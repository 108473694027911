import MainCanvas from 'components/MainCanvas/MainCanvas';

function App() {
  return (
    <div className="App w-full h-full bg-[#000000] overflow-hidden">
      <MainCanvas />
    </div>
  );
}

export default App;
