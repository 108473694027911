import CASE_STUDY_1 from 'assets/images/case-studies/case-1.png';
import CASE_STUDY_2 from 'assets/images/case-studies/case-2.png';
import CASE_STUDY_3 from 'assets/images/case-studies/case-3.png';
import CASE_STUDY_4 from 'assets/images/case-studies/case-4.png';
import CASE_STUDY_5 from 'assets/images/case-studies/case-5.png';
import CASE_STUDY_6 from 'assets/images/case-studies/case-6.png';
import Sound from 'utils/sound';

const studies = [
  { img: CASE_STUDY_1, link: 'https://passengers.space' },
  { img: CASE_STUDY_2, link: 'https://exp-infinite-passerella.lusion.co' },
  { img: CASE_STUDY_3, link: 'https://www.sprite.com/zerolimits' },
  { img: CASE_STUDY_4, link: 'https://www.empathyexperiment.com/the-joke' },
  { img: CASE_STUDY_5, link: 'https://persepolis.getty.edu' },
  { img: CASE_STUDY_6, link: 'https://www.bluemarinefoundation.com/the-sea-we-breathe/' }
];

export default function CaseStudies() {
  return (
    <>
      {studies.map((study) => {
        return (
          <a
            className="w-[16%] max-w-[482px] mr-[2%] cursor-pointer hover:scale-90 duration-500 ease-out"
            href={study.link}
            target="_blank"
            rel="noreferrer"
            onMouseDown={() => Sound.playSfxClick()}
            onMouseEnter={() => Sound.playSfxHover()}
          >
            <img alt="stats" className="w-[100%]" src={study.img} />
          </a>
        );
      })}
    </>
  );
}
