import { gsap, Power2 } from 'gsap';
import { useProgress } from '@react-three/drei';
import { useEffect, useState, useRef } from 'react';
import useAppStore from 'hooks/useAppStore';
import Sound from 'utils/sound';

function Circle({ id, strokeWidth = 4 }: { id: string; strokeWidth?: number }) {
  return (
    <div id={id} className="origin-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
      <svg width="512" height="512" className=" ">
        <circle
          id="indicator"
          style={{
            strokeDasharray: `${256 * Math.PI * 2}px`,
            strokeDashoffset: `${256 * Math.PI * 2}px`
          }}
          cx="256"
          cy="256"
          r="252"
          transform="rotate(90, 256, 256) translate(512, 512) scale(-1, -1)"
          stroke="#FF8D24"
          strokeWidth={`${strokeWidth}`}
          fill="none"
        />
      </svg>
    </div>
  );
}

export default function EnterScreen({ enter }: { enter: Function }) {
  const { progress } = useProgress();
  const [show, setShow] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [hovered, setHovered] = useState(false);

  const setHasLoaded = useAppStore((s) => s.setHasLoaded);

  const counter = useRef<number>(1);

  useEffect(() => {
    if (progress === 100) {
      gsap.to('#indicator', {
        strokeDashoffset: `0`,
        duration: 2,
        ease: Power2.easeIn,
        onComplete: () => setLoaded(true)
      });
      return;
    }
    const interval = setInterval(() => {
      const fakeProgress = Math.max(progress, counter.current * 5);
      gsap.to('#indicator', {
        strokeDashoffset: `${Math.PI * 252 * (1 - fakeProgress / 100) * 2}`,
        duration: 2,
        ease: Power2.easeIn
      });
      if (counter.current < 14) counter.current++;
    }, 2000);
    return () => clearInterval(interval);
  }, [progress]);


  const hoverAnimation = () => {
    gsap.to('#circle', { scale: '0.9', ease: Power2.easeOut });
    gsap.to('#circle1', { scale: '0.9', ease: Power2.easeOut });
    gsap.to('#circle2', { scale: '0.9', ease: Power2.easeOut });
    gsap.to('#circle3', { scale: '0.9', ease: Power2.easeOut });
  };

  const dissapear = () => {
    Sound.playSfxClick();
    enter();
    gsap.to('#circle', { scale: '10', duration: 2, ease: Power2.easeOut });
    gsap.to('#circle1', { opacity: '0', duration: 0 });
    gsap.to('#circle2', { opacity: '0', duration: 0 });
    gsap.to('#circle3', { opacity: '0', duration: 0 });

    gsap.to('#enter-screen', {
      opacity: 0,
      duration: 0.5,
      ease: Power2.easeInOut,
      onComplete: () => {
        setShow(false);
      }
    });
  };

  useEffect(() => {
    setHasLoaded(loaded);
  }, [loaded]); // eslint-disable-line

  useEffect(() => {
    if (hovered) {
      gsap.to('#circle1', { scale: '0.95', opacity: 0.7, duration: 0.3, ease: Power2.easeOut });
      gsap.to('#circle2', { scale: '0.9', opacity: 0.35, duration: 0.3, ease: Power2.easeOut });
      gsap.to('#circle3', { scale: '0.85', opacity: 0.2, duration: 0.3, ease: Power2.easeOut });
    } else {
      gsap.to('#circle1', { scale: '1.0', opacity: 1.0, duration: 0.3, ease: Power2.easeOut });
      gsap.to('#circle2', { scale: '1.0', opacity: 1.0, duration: 0.3, ease: Power2.easeOut });
      gsap.to('#circle3', { scale: '1.0', opacity: 1.0, duration: 0.3, ease: Power2.easeOut });
    }
  }, [hovered]);

  return !show ? null : (
    <div
      id="enter-screen"
      className="bg-[#040404] absolute top-0 left-0 h-full w-full flex justify-center items-center overflow-hidden"
    >
      <div
        id="button"
        className={`
        text-orange text-[40px] md:text-[40px] h-[512px] w-[512px] text-center bg-[#040404] relative 
          ${loaded && 'cursor-pointer'}
        `}
        {...(loaded && {
          onMouseEnter: () => Sound.playSfxHover(),
          onMouseOver: () => setHovered(true),
          onMouseOut: () => setHovered(false),
          onMouseDown: () => hoverAnimation(),
          onMouseUp: () => dissapear()
        })}
      >
        <Circle id="circle"></Circle>
        <Circle id="circle1" strokeWidth={2.0}></Circle>
        <Circle id="circle2" strokeWidth={2.0}></Circle>
        <Circle id="circle3" strokeWidth={2.0}></Circle>

        {loaded ? (
          <div className="text-orange absolute top-1/2 left-1/2 text-[40px] -translate-y-1/2 -translate-x-1/2 text-center w-full uppercase">
            press to enter
          </div>
        ) : (
          <div className="text-orange absolute top-1/2 left-1/2 text-[24px] -translate-y-1/2 -translate-x-1/2 text-center w-full uppercase">
            Loading peach worlds...
          </div>
        )}
      </div>
    </div>
  );
}
