import SPACESHIP_MODEL from 'assets/models/spaceship.glb';
import React, { useEffect, useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { Color, Group, Mesh, MeshStandardMaterial, Vector3, VideoTexture, PerspectiveCamera } from 'three';
import { gsap, Power2 } from 'gsap';
import VIDEO_ALPHA_MAP from 'assets/textures/alpha-map-earth-video.png';
import useAppStore from 'hooks/useAppStore';
import { useThree } from '@react-three/fiber';
import useLookAroundCamera from 'hooks/useLookAroundCamera';
import useTheaterAnimation from 'hooks/useTheaterAnimation';

const SPACESHIP_BURN_ANIMATION = (target: MeshStandardMaterial) => {
  const animation = { value: 0.0 };
  gsap.to(animation, {
    ease: Power2.easeInOut,
    duration: 3,
    repeat: -1,
    yoyoEase: true,
    value: 1.0,
    onUpdate: () => {
      const { value } = animation;
      target.emissiveIntensity = 1.0 * value;
    }
  });
};

const SPACESHIP_ANIMATION = (target: Group) => {
  const animation = { value: 0.0 };
  gsap.to(animation, {
    ease: Power2.easeInOut,
    duration: 3,
    repeat: -1,
    yoyoEase: true,
    value: 1.0,
    onUpdate: () => {
      const { value } = animation;
      target.position.z = value * 150;
    }
  });
};

interface IntroSceneProps {
  visible: boolean;
  videoTexture: VideoTexture;
}
export default function IntroScene({ visible, videoTexture }: IntroSceneProps) {
  const { nodes, materials } = useGLTF(SPACESHIP_MODEL);
  const camera = useThree((s) => s.camera);
  const { hasEnteredAfterLoad } = useAppStore();

  useLookAroundCamera(camera as PerspectiveCamera, 0.1, 0.2, {
    disabled: !visible,
    zoomOptions: {
      speed: 0.05,
      minFOV: 20,
      maxFOV: 80
    }
  });

  const introAnimation = useTheaterAnimation('intro');

  useEffect(() => {
    if (!visible || !hasEnteredAfterLoad) return;
    SPACESHIP_ANIMATION(spaceship.current!);
    SPACESHIP_BURN_ANIMATION(materials.Alpha as MeshStandardMaterial);

    (earth.current! as Mesh).lookAt(new Vector3(0, 0, 0));
    introAnimation.play();
  }, [visible, hasEnteredAfterLoad]); // eslint-disable-line

  useEffect(() => {
    (materials.Alpha as MeshStandardMaterial).emissiveIntensity = 1;
    (materials.Alpha as MeshStandardMaterial).blending = THREE.AdditiveBlending;
    (materials.Alpha as MeshStandardMaterial).emissive = new Color('orange');
    (materials.Alpha as MeshStandardMaterial).color = new Color('black');
    (materials.Alpha as MeshStandardMaterial).transparent = true;
    (materials.Alpha as MeshStandardMaterial).emissiveMap = null;
    (materials.Alpha as MeshStandardMaterial).side = THREE.DoubleSide;
    (materials.Alpha as MeshStandardMaterial).needsUpdate = true;
    (materials.Alpha as MeshStandardMaterial).toneMapped = false;

    SPACESHIP_ANIMATION(spaceship.current!);

    SPACESHIP_BURN_ANIMATION(materials.Alpha as MeshStandardMaterial);

    (earth.current! as Mesh).lookAt(new Vector3(0, 0, 0));
  }, [materials]);

  const spaceship = useRef(null);
  const videoAlphaMapTexture = useTexture(VIDEO_ALPHA_MAP);
  const earth = useRef(null);

  return (
    <group visible={visible}>
      <directionalLight
        color="white"
        rotation={[Math.PI / 8.0, Math.PI / 2.0, 0]}
        position={[5, 5, -5]}
        intensity={1.0}
      />
      <group scale={[0.0001, 0.0001, 0.0001]}>
        <group rotation={[-Math.PI / 2, 0, 0]}>
          <mesh
            position={[-300000, 9 * 30000, 0]}
            scale={[9 * 100000, (9 * 100000) / (16 / 9), 9 * 100000] as never}
            rotation={[Math.PI / 2, (Math.PI / 2) * 0.6, 0]}
            ref={earth}
          >
            <planeGeometry scale={[1, 1, 1] as never}></planeGeometry>
            <meshBasicMaterial
              side={THREE.FrontSide}
              fog={false}
              transparent={true}
              alphaMap={videoAlphaMapTexture}
              map={videoTexture}
            ></meshBasicMaterial>
          </mesh>
          <group rotation={[Math.PI / 2, 0, 0]} ref={spaceship}>
            <pointLight
              distance={0.015}
              color="orange"
              position={[0.19, -110.95, 1000.84]}
              intensity={20.0}
            ></pointLight>
            <group position={[0.19, -110.95, 955.84]} scale={39.37}>
              <mesh
                castShadow
                receiveShadow
                geometry={(nodes.Burn_Burn_0 as Mesh).geometry}
                material={materials.Alpha}
              />
            </group>
            <group position={[-84.75, 0, 0]} rotation={[-Math.PI, 0, -Math.PI]} scale={39.37}>
              <group position={[-2.15, -2.82, 27.52]}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={(nodes['SpaceX_low_Material_#2_0'] as Mesh).geometry}
                  material={materials.Spaceship}
                />
              </group>
            </group>
            <group position={[-84.75, 0, 0]} rotation={[-Math.PI, 0, -Math.PI]} scale={39.37}>
              <group position={[-2.15, -2.82, 27.52]}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={(nodes.SpaceX_op_Glass_0 as Mesh).geometry}
                  material={materials.Spaceship}
                />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  );
}
