import React, { useMemo } from 'react';
import { useGLTF } from '@react-three/drei';
import ASTEROID_MODEL from 'assets/models/asteroid.glb';
import { InstancedMesh, Mesh, Matrix4, Euler, Vector3, MeshPhysicalMaterial, Quaternion } from 'three';
import { useFrame } from '@react-three/fiber';

const RANDOM_ROTATIONS: Quaternion[] = [
  [0.47206509504276045, 1.0595647735584537, 2.7202024941922214],
  [1.4533113164514697, 1.8898117411413162, 2.7861262660925896],
  [1.1767703423666551, 0.7935401522300756, 2.942855655318626],
  [3.0063290670663876, 2.9199845301773784, 2.505972672101561],
  [0.3721769290069261, 1.741557196356445, 0.65092164997367],
  [3.035086281957567, 2.186112242554213, 1.0243306533331464],
  [1.6788684674421182, 0.45944770887426606, 1.0718529014351061],
  [0.660631776217734, 2.2792220310298466, 1.1704688504331124],
  [0.05609476284349706, 1.2945708372655969, 1.3969309473479958],
  [2.6148688837959075, 0.06124779962193292, 0.2456619008925182],
  [2.2929870146475566, 0.629949998811866, 1.8078787239057486],
  [0.9290559411893181, 0.5028034913250621, 2.957251653003138],
  [2.578184519737426, 0.7547578177262463, 0.18674410377455],
  [2.8864125817867525, 0.21005651031811734, 0.31146564806357063],
  [1.6433029791155633, 0.17049981425859556, 0.22914366786736426],
  [1.5797422797523595, 1.7371427219851165, 2.800623309828405],
  [2.315798260864445, 0.745525289207277, 0.15344510945842188],
  [2.6237345650568744, 2.5701693137631962, 2.4382277900523297],
  [0.37038661013819135, 2.492438403717194, 1.32910959730668],
  [2.6859206713261257, 0.3146247153726117, 1.4617550774350339],
  [1.3636094570079744, 0.33164617046654293, 1.8118174300003844],
  [1.3748871311708981, 2.404779567297547, 0.37627708123995895],
  [0.3731285761173984, 1.7885747171123452, 0.5154042504022538],
  [2.3897479655195313, 0.4856174566192586, 3.1074527904256373],
  [0.2583684502109504, 2.489635568705073, 0.8902565153989668],
  [2.8282570143273054, 2.737428742727258, 0.9311145398414562],
  [2.3286128980205163, 0.24138090785576063, 1.906548939242001],
  [1.0423208480708923, 0.21786664450665205, 2.3222415555275493],
  [0.7284771893049506, 0.1786548330506156, 2.77135871411867],
  [0.5357271081616246, 1.0765544273447119, 0.9007454791988069],
  [1.3185345523888015, 1.3764466426546316, 1.3865002259221864],
  [2.4768431610151302, 2.440049109169628, 0.6070035125325335],
  [1.056201173472511, 0.5459672969584428, 2.8601636199431217],
  [1.303067731600382, 1.5470035927142616, 2.441744917340096],
  [1.4466975278582028, 2.8803316919154978, 2.2643860531204028],
  [2.301434345240542, 1.3263985357590864, 0.27928921444495347],
  [2.3806670518650423, 2.7298832494070147, 2.934847602189488],
  [1.5893542779995555, 2.5566574419518915, 0.5381570450355784],
  [2.3720092393135874, 0.984533472648768, 2.33998662976422],
  [1.9713325752197104, 3.0337613937718553, 2.226257090974601],
  [1.4328309359286378, 1.8478464851607894, 0.38878616091753315],
  [0.6541300268244757, 0.32466302222453586, 0.1181355182403963],
  [1.1187338604778279, 0.9815954150596707, 0.6190800773898869],
  [2.5860030525218742, 0.5469254335245199, 2.346213264566939],
  [0.2969328223861215, 0.6146727176188872, 2.3851237681620914],
  [0.48438181205330444, 1.6780872226677097, 1.0500303731361682],
  [0.07498151946032096, 2.2334944726751527, 0.9144074303023704],
  [0.4175550799669576, 2.8162175054020744, 2.6982194840815916],
  [0.4689413322615941, 2.153413816260365, 2.326310886692268],
  [2.759500771131779, 0.5395151423015391, 1.023977049996074],
  [2.719251056861138, 3.0653054104563333, 2.9653350901666427],
  [2.843867569273179, 0.9553623051993851, 2.4343587589827536],
  [1.0274111232574, 0.935000569218924, 1.9760618751768884],
  [0.6956757091045787, 2.375026812968866, 2.714528947111864],
  [2.1748351632767777, 1.897152258568012, 1.2471160847548035],
  [1.2773312360455005, 0.022665674081953854, 0.8980264637189594],
  [2.0765043744656455, 2.568256955158169, 0.33834022483953874],
  [2.9367389056575175, 0.6643068758417195, 1.6900989018372907],
  [2.613799953723565, 2.119947175974536, 2.1888053081406884],
  [2.8262746111208497, 1.1510380792321617, 2.019021969882712]
].map((rotation) => new Quaternion().setFromEuler(new Euler(...rotation)));

const RANDOM_POSITIONS: Vector3[] = [
  [955.7599358933571, 90.73767055313226, 959.4287815706236],
  [4276.413559572861, 1042.7913112641038, 3063.4736276726553],
  [1378.6433408796477, 965.4419425018774, 2809.4520452231964],
  [1155.0985414550105, 209.64679718778677, 4658.484002335778],
  [1841.8945110921445, 308.0249095161316, 2193.9654715295223],
  [4743.80707657734, 793.855203321143, 3180.2951711262353],
  [3961.9746479562755, 640.3998759251441, 104.62109879205806],
  [3591.635409333565, 237.69923096696388, 384.1073863198468],
  [892.9166351060629, 525.1469798724228, 4915.675843638867],
  [2684.1116393747625, 824.0394340194146, 2182.716413249404],
  [1388.4879370135643, 949.7616770616881, 169.2718379272722],
  [2727.2997584638947, 649.82147971381, 4612.369319789042],
  [4809.528521324494, 227.814143118756, 1197.2956231903017],
  [10.64656247815422, 666.9385425777474, 1060.093699985145],
  [3071.853365237699, 818.6451886391219, 72.58210680433963],
  [2576.8735488241878, 65.4701762557092, 1160.2510057223235],
  [953.1739073820477, 543.8934375444144, 2895.87101848936],
  [1669.6227079607288, 70.49794124891903, 2041.5584967304171],
  [4847.846212239532, 1076.6744173365723, 1780.865435156582],
  [3653.1783130704307, 894.8603082982894, 2950.1380738601206],
  [4166.195016804335, 193.5854500611699, 829.0588791928743],
  [3016.2947278014312, 439.5785969714861, 4117.799124620796],
  [4546.481256325052, 323.41242212465073, 1595.1590769104378],
  [1635.2242787698276, 1175.2062000082635, 1399.8351030859167],
  [3870.9793853011956, 874.3315698403751, 3080.7964939294566],
  [1457.1282117545957, 616.1544331661989, 2488.3147081073853],
  [1566.8073346730241, 267.9165741017774, 3624.6556061075003],
  [990.7996111639022, 452.9053783686362, 1338.0215649022025],
  [4154.466006587779, 1082.3812478449677, 956.6348041401267],
  [3046.8962532176397, 680.664497751416, 1940.3480268778687],
  [4501.588058629189, 976.184041933541, 3182.646392222872],
  [1683.0492970575754, 1123.4378028739463, 4856.726656920285],
  [4976.493952904948, 507.6687290935116, 3936.7756731374025],
  [2941.893739299013, 46.67471780201913, 4196.619955735362],
  [4133.254475253588, 436.0937072438884, 251.52913363985718],
  [4015.0226348307074, 615.4727283538024, 1305.5945905567949],
  [1358.1321131507277, 861.7357258614818, 1213.997884969572],
  [79.25483247914745, 498.5334676468332, 471.5676677843505],
  [4678.323925688527, 1131.2134413475574, 1555.500557207135],
  [1033.729074458151, 955.7844074544224, 3171.4582177193824],
  [3016.1018283531375, 515.6654056052043, 1128.1111852716429],
  [1272.5484981763766, 1239.0215438670753, 2168.983971983836],
  [2536.329468498498, 19.7872712643216, 2990.241076667901],
  [4674.195999548414, 1037.1027455696487, 1036.9284380160468],
  [3356.204165606484, 311.1253410013382, 3113.874079749346],
  [1046.3592588980318, 797.0147266838862, 933.0616369185262],
  [1717.5562444800898, 624.068787446856, 3614.7951399815356],
  [129.9101133867102, 9.976170673737972, 4103.390871786981],
  [4039.055956929797, 690.1762115728908, 436.8602646577684],
  [605.3005459378762, 284.99322340510133, 695.7157989884432],
  [2629.5934667774873, 479.48112478569664, 4284.569698613458],
  [3840.7543581109458, 506.38322124483165, 4361.967152471139],
  [954.9477215701079, 621.5027825533632, 3331.9785959483197],
  [3705.4451260257397, 607.1029314540881, 827.738233411579],
  [4960.94298964428, 59.772962997452254, 2474.1295890072884],
  [4315.132350642254, 135.68173629650448, 1361.7772610102145],
  [2874.0464766309206, 798.5365423421902, 75.63534527984517],
  [794.2274945274941, 715.4926102651328, 1394.2328645065181],
  [3161.7907897381046, 203.5546252771724, 1770.9694315399224],
  [4872.94249748905, 785.3088023449269, 3973.8242656517978]
].map((position) => new Vector3(...position));

const RANDOM_SCALES: Vector3[] = [
  [0.7107521530964569, 0.7957000347152758, 0.7280420892787896],
  [0.5379040836868713, 0.7494325901535546, 0.7109358727376052],
  [0.8402990740000311, 0.880534368505725, 0.5063845570056038],
  [0.5005249046601061, 0.7658577124886041, 0.9765708079718143],
  [0.6074099224332146, 0.6372567843663796, 0.764645739093859],
  [0.9955602797167156, 0.6053382167007278, 0.9310627256298205],
  [0.8709339555366298, 0.955703772338836, 0.6289389007166237],
  [0.7844645460130498, 0.5105668739244786, 0.6405558813173486],
  [0.9428769794311638, 0.644291102323601, 0.5668781591042991],
  [0.8502124590793202, 0.9522093912878787, 0.6335925300955605],
  [0.7110968260312185, 0.5669098936940863, 0.8271602102540905],
  [0.6804421754182405, 0.648374710328428, 0.7764805654961873],
  [0.6727473822827502, 0.6984363478043816, 0.9585640954914296],
  [0.7111068597007905, 0.8953783827299919, 0.8906779179037267],
  [0.8135765750982361, 0.6537261787591239, 0.8110299894945407],
  [0.9121339443412624, 0.9950915151697276, 0.9608712521749754],
  [0.9982613599603408, 0.9136658044700827, 0.6153815859577675],
  [0.5401927984506308, 0.862566578008064, 0.9498890887844675],
  [0.8113237882259388, 0.9407540614829417, 0.784758139314476],
  [0.8903934034548067, 0.5189773210037771, 0.9622456230918854],
  [0.5714772926589893, 0.84353575440693, 0.7874191688130805],
  [0.708348901589622, 0.9153504737694883, 0.9088146231020526],
  [0.7500776744618772, 0.5577987113996894, 0.6447898701732113],
  [0.7804691374951732, 0.676413822832362, 0.8712349803868014],
  [0.8293019150924209, 0.5448159392439134, 0.9070710487112861],
  [0.8467153050290184, 0.87803119524944, 0.7540888879242507],
  [0.7194693330367192, 0.9230602619718633, 0.90577748752636],
  [0.6843392263456048, 0.590361331261688, 0.9146726211995051],
  [0.9048240021333853, 0.7605407982130832, 0.9695673204342647],
  [0.8770657442603715, 0.6688448530554287, 0.5764822757089865],
  [0.7404536102058047, 0.9782810489528504, 0.6042729191392091],
  [0.789477740270901, 0.541793119253053, 0.5562852592829031],
  [0.744174530161203, 0.9380599119387297, 0.776616119578913],
  [0.70478710594162, 0.8772088860074656, 0.9135013685512918],
  [0.9383721960034351, 0.8768033757609563, 0.9273965613050463],
  [0.6466632854655568, 0.752847114931603, 0.7948995714097427],
  [0.5436365945238364, 0.8334015582500894, 0.5778377383991713],
  [0.8120635620896473, 0.5201726062699741, 0.8376050749875781],
  [0.8587290040160227, 0.6496237301252463, 0.7824684281709897],
  [0.6823609125368384, 0.9648598669557359, 0.992553775328322],
  [0.7195930626533252, 0.7981692768063352, 0.7027007518371449],
  [0.9190191432985142, 0.8676722143353385, 0.8755218884722247],
  [0.8060483002057632, 0.6364360854951253, 0.867072166592203],
  [0.872017371854606, 0.7892519801156642, 0.8183959873242876],
  [0.9473498829072895, 0.6474523287227129, 0.5015707273199448],
  [0.733781277150479, 0.6945809429523393, 0.9539550790700375],
  [0.8178741952254005, 0.5253497823671562, 0.7223781379046319],
  [0.9607892845855603, 0.9189532823830443, 0.6369341357261283],
  [0.8625221151393545, 0.7557486699094029, 0.9787053023334021],
  [0.544638190128393, 0.8037434529835668, 0.9782957547492661],
  [0.8723510607222354, 0.7658026264373801, 0.8085616220118642],
  [0.7713282461058912, 0.9529561321261271, 0.5409605544936065],
  [0.7906464867652622, 0.6134219655921207, 0.8819602291969109],
  [0.7043046744448489, 0.7824141381500629, 0.6226384696363871],
  [0.9924899142370907, 0.8020966707122072, 0.831272825008686],
  [0.5226995018891339, 0.6916658935182092, 0.9387732095964466],
  [0.7379290140107306, 0.6238281216522246, 0.6518380453005483],
  [0.9826538988361028, 0.9320548521048122, 0.5913146143635268],
  [0.5286990403856324, 0.8389145759465317, 0.810265665563425],
  [0.5487096779069475, 0.6680037551447555, 0.505728765502753]
].map((scale) => new Vector3(...scale).multiplyScalar(5.0));

const Y_AXIS = new Vector3(0, 1, 0);

const COUNT = 30;

export function Asteroids({ name }: { name: string }) {
  const { nodes, materials } = useGLTF(ASTEROID_MODEL);

  const instancedMesh = useMemo(() => {
    const geometry = (nodes.Asteroid as Mesh).geometry;
    (materials.Asteroid as MeshPhysicalMaterial).metalness = 0.3;

    return new InstancedMesh(geometry, materials.Asteroid, COUNT);
  }, []); // eslint-disable-line

  useFrame((state, delta) => {
    if (!instancedMesh?.parent?.parent?.visible) return;
    const offset = name === 'asteroids' ? 0 : COUNT;
    const matrix = new Matrix4();
    const newRotation = new Quaternion().setFromAxisAngle(Y_AXIS, delta * 0.05);
    for (let i = 0; i < COUNT; i++) {
      const index = offset + i;
      RANDOM_ROTATIONS[index].multiply(newRotation);
      matrix.compose(RANDOM_POSITIONS[index], RANDOM_ROTATIONS[index], RANDOM_SCALES[index]);
      instancedMesh.setMatrixAt(i, matrix);
    }
    instancedMesh.instanceMatrix.needsUpdate = true;
  });

  return (
    <group
      userData={{
        animated: true,
        animations: [{ objectName: name, sheetName: 'spaceSequence' }]
      }}
    >
      <primitive object={instancedMesh} />
    </group>
  );
}
