import {
  DirectionalLight,
  Material,
  MathUtils,
  Mesh,
  Object3D,
  PerspectiveCamera,
  ShaderMaterial,
  Vector3
} from 'three';
import { IProjectConfig, onChange, types, val } from '@theatre/core';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { AnimationData } from 'interfaces/interfaces';
import { AnimationContext } from './useTheaterContext';

export default function useTheatherJS(projectId: string, configFile: IProjectConfig = {}) {
  const { project, setProjectConfig } = useContext(AnimationContext);

  const addSceneAnimations = useCallback(
    (scene: Object3D) => {
      scene.traverse((object) => {
        if (object.userData.animated) {
          addObjectAnimation(object, object.userData.animations);
        }
        if (object instanceof Mesh && object.material instanceof Material && object.material.userData.animated) {
          addObjectAnimation(object.material, object.material.userData.animations);
        }
      });
    },
    [project] // eslint-disable-line
  );

  const addObjectAnimation = useCallback(
    (obj: Object3D | Material, animations: AnimationData[]) => {
      if (!project) return;
      animations?.forEach((animation) => {
        const { sheetName, autoplay = false, loop = false, objectName } = animation;
        const sheet = project.sheet(sheetName);
        if (autoplay) {
          project.ready.then(() => {
            sheet.sequence.play();
          });
        }
        if (loop) {
          onChange(sheet.sequence.pointer.playing, (playing) => {
            if (!playing && sheet.sequence.position >= val(sheet.sequence.pointer.length)) sheet.sequence.play();
          });
        }

        if (obj instanceof Object3D) {
          const properties = {
            rotation: types.compound({
              x: types.number(MathUtils.radToDeg(obj.rotation.x), {
                range: [-360, 360],
                nudgeMultiplier: 0.02
              }),
              y: types.number(MathUtils.radToDeg(obj.rotation.y), {
                range: [-360, 360],
                nudgeMultiplier: 0.02
              }),
              z: types.number(MathUtils.radToDeg(obj.rotation.z), {
                range: [-360, 360],
                nudgeMultiplier: 0.02
              })
            }),
            position: types.compound({
              x: types.number(obj.position.x, { range: [-1000, 1000], nudgeMultiplier: 0.02 }),
              y: types.number(obj.position.y, { range: [-1000, 1000], nudgeMultiplier: 0.02 }),
              z: types.number(obj.position.z, { range: [-1000, 1000], nudgeMultiplier: 0.02 })
            }),
            scale: types.compound({
              x: types.number(obj.scale.x, { range: [-300, 300], nudgeMultiplier: 0.02 }),
              y: types.number(obj.scale.y, { range: [-300, 300], nudgeMultiplier: 0.02 }),
              z: types.number(obj.scale.z, { range: [-300, 300], nudgeMultiplier: 0.02 })
            })
          };

          const sheetObj = sheet.object(objectName, properties);
          sheetObj.onValuesChange((values) => {
            const { rotation, position, scale } = values;
            obj.rotation.setFromVector3(
              new Vector3(
                MathUtils.degToRad(rotation.x),
                MathUtils.degToRad(rotation.y),
                MathUtils.degToRad(rotation.z)
              )
            );
            obj.position.set(position.x, position.y, position.z);
            obj.scale.set(scale.x, scale.y, scale.z);
            if (obj instanceof PerspectiveCamera) {
              (obj as PerspectiveCamera).updateProjectionMatrix();
              (obj as PerspectiveCamera).updateMatrix();
            }
            if (obj instanceof DirectionalLight) {
              (obj as DirectionalLight).updateMatrix();
            }
          });
        }

        if (obj instanceof ShaderMaterial) {
          const properties = {
            opacity: types.number(obj.uniforms.opacity.value, { range: [0, 1] })
          };

          const sheetObj = sheet.object(objectName, properties);
          sheetObj.onValuesChange((values) => {
            const { opacity } = values;
            obj.uniforms.opacity.value = opacity;

            obj.uniformsNeedUpdate = true;
            obj.needsUpdate = true;
          });
        } else if (obj instanceof Material) {
          const properties = { opacity: types.number(obj.opacity, { range: [0, 1] }) };

          const sheetObj = sheet.object(objectName, properties);
          sheetObj.onValuesChange((values) => {
            const { opacity } = values;
            obj.opacity = opacity;
          });
        }
      });
    },
    [project]
  );

  useEffect(() => {
    setProjectConfig(projectId, configFile);
  }, [projectId, configFile]); // eslint-disable-line

  return useMemo(() => ({ addSceneAnimations }), [addSceneAnimations]);
}
