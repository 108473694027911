import { AppStore } from 'interfaces/interfaces';
import { create } from 'zustand';

function skipNextSteps(step: number) {
  if (step === 15) return 18;
  if (step === 23) return 25;
  return step + 1;
}

function skipPreviousSteps(step: number) {
  if (step === 18) return 15;
  if (step === 25) return 23;
  return step - 1;
}

const useAppStore = create<AppStore>()((set) => ({
  currentStep: 0,
  quality: 2,
  currentScreen: null,
  direction: 'normal',
  totalSteps: 26,
  showOverlay: true,
  hasEnteredAfterLoad: false,
  hasLoaded: false,
  currentTeamMember: null,
  background: 'default',
  menuNavigation: false,
  loadingBackground: false,
  setCurrentScreen: (screen) => set((state) => ({ currentScreen: screen })),
  setCurrentTeamMember: (teamMember) => set((state) => ({ currentTeamMember: teamMember })),
  setShowOverlay: (showOverlay) => set((state) => ({ showOverlay })),
  setDirection: (direction) => set((state) => ({ direction })),
  setHasEnteredAfterLoad: (hasEnteredAfterLoad) => set((state) => ({ hasEnteredAfterLoad })),
  setHasLoaded: (hasLoaded) => set((state) => ({ hasLoaded })),
  setLoadingBackground: (loadingBackground) => set((state) => ({ loadingBackground })),
  setCurrentStep: (currentStep) => set((state) => ({ currentStep, menuNavigation: true, direction: 'normal' })),
  setQuality: (quality) => set((state) => ({ quality })),
  nextStep: () =>
    set((state) => ({
      currentStep: skipNextSteps(state.currentStep),
      direction: 'normal',
      menuNavigation: false
    })),
  previousStep: () =>
    set((state) => ({
      currentStep: skipPreviousSteps(state.currentStep),
      direction: 'reverse',
      menuNavigation: false
    })),
  setBackground: (backgroundUrl) => set((state) => ({ background: backgroundUrl }))
}));

export default useAppStore;
