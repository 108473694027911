import { Camera, Group, Matrix4, Vector3 } from 'three';

export function moveCameraAlongCurve(camera: Camera, target: Group, points: Vector3[], height: number, time: number) {
  const length = points.length;
  let index = Math.floor(time * length);

  if (index > length - 2) return;
  const lerpAmount = time * length - index;

  const position = points[index]
    .clone()
    .lerp(points[index + 1], lerpAmount)
    .applyMatrix4(target.matrix)
    .add(new Vector3(0, -height, 0));

  camera.parent?.position.copy(position);
  if (index > length - 12) return;
  const nextPosition = points[index + 10]
    .clone()
    .lerp(points[index + 11], lerpAmount)
    .applyMatrix4(target.matrix);

  const rotation = new Matrix4().lookAt(position, nextPosition, new Vector3(0, -1 * Math.sign(height), 0));
  camera.parent?.setRotationFromMatrix(rotation);
}
