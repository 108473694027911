import bcrypt from 'bcryptjs';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import HEADPHONES from 'assets/icons/headphones.svg';
import SCREEN_0_MOBILE_VERSION_COMING_SOON from 'assets/images/screen-0-mobile-version-coming-soon.png';
import SCREEN_0_CONTROLS from 'assets/images/screen-0-controls.png';
import CloseButton from 'components/CloseButton/CloseButton';
import Sound from 'utils/sound';
import { isMobileOrTablet } from 'utils/utils';

export default function CodeScreen({ onStart, onClose }: { onStart?: any; onClose?: any }) {
  const [code, setCode] = useState<string>('');
  const [isKeyboardInfoScreen, setIsKeyboardInfoScreen] = useState(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setError('');
  }, [code]);

  useLayoutEffect(() => {
    if (isKeyboardInfoScreen) {
      Sound.play(Sound.screenKeyboardInfoVoiceOver);
    } else {
      Sound.play(Sound.screenCodeVoiceOver);
    }
    return () => {
      Sound.pause(Sound.screenCodeVoiceOver);
      Sound.pause(Sound.screenKeyboardInfoVoiceOver);
    };
  }, [isKeyboardInfoScreen]);

  // keeping for making new hash later
  // const hashSync = bcrypt.hashSync('code');
  // console.log('🐶 [CodeScreen.tsx : 15] => hashSync', hashSync);

  const onSubmit = useCallback(() => {
    const hash = '$2a$10$9Uvds8wI0g3ez/pB.N0LvuPhLgSTfGsX/ULB/Ai7mJdIhVE74tc1W'; // hashed code
    if (bcrypt.compareSync(code.toUpperCase(), hash)) {
      setIsKeyboardInfoScreen(true);
    } else {
      setError('YOUR CODE IS INCORRECT');
    }
  }, [code]);

  const handleKeyPress = useCallback(
    (e: any) => {
      if (e?.keyCode === 13) {
        if (isKeyboardInfoScreen) {
          onStart();
        } else {
          Sound.playSfxClick();
          onSubmit();
        }
      }
    },
    [isKeyboardInfoScreen, onStart, onSubmit]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center absolute top-0 pointer-events-none">
      <CloseButton onClose={onClose} />
      {isKeyboardInfoScreen ? (
        <>
          <img alt="hp" className="w-[777px] mb-[5%]" src={SCREEN_0_CONTROLS} />
          <button
            className="mt-[12px] border-solid  border-[#ffffff]/30 tracking-widest px-[46px] py-[18px] border-[1px] uppercase leading-[42px] text-[24px] md:text-[32px] active:scale-90 hover:text-orange text-[#FFFFFF] orange-corners-button pointer-events-auto "
            onClick={onStart}
            onMouseDown={() => Sound.playSfxClick()}
            onMouseEnter={() => Sound.playSfxHover()}
          >
            CONTINUE
          </button>
        </>
      ) : (
        <>
          {isMobileOrTablet() || window.innerWidth < 950 ? (
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <img alt="stats" className="w-[311px]" src={SCREEN_0_MOBILE_VERSION_COMING_SOON} />
            </div>
          ) : (
            <div className="flex flex-col items-center pointer-events-auto">
              <span className="top-[10px] text-[#FF2459] text-h3">{error}</span>
              <input
                autoFocus
                className={`
                  w-[425px] h-[64px] mt-[16px] border-2 bg-transparent text-b text-white text-center outline-none uppercase
                  ${error ? 'border-[#FF2459]' : 'border-white focus:border-orange'} 
                `}
                placeholder="ENTER CODE"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <button
                className={`
                  w-[425px] h-[84px] mt-[24px] text-b text-black active:scale-90
                  ${!!code && !error ? 'bg-orange' : 'bg-[#D9D9D9] bg-opacity-30 pointer-events-none'}
                `}
                onClick={onSubmit}
                onMouseDown={() => Sound.playSfxClick()}
              >
                START JOURNEY
              </button>
              <img alt="hp" className="absolute bottom-[150px]" src={HEADPHONES} />
              <span className="absolute bottom-[100px] text-h3 text-lightorange uppercase">
                This immersive journey requires audio.
              </span>
              <span className="absolute bottom-[70px] text-h3 text-lightorange uppercase">
                Use headphones for best experience.
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
}
