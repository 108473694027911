import { memo, useEffect, useState } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { Mesh, VideoTexture, PerspectiveCamera } from 'three';
import { gsap, Power2 } from 'gsap';
import useAppStore from 'hooks/useAppStore';
import { useThree, useFrame } from '@react-three/fiber';
import useLookAroundCamera from 'hooks/useLookAroundCamera';
import useTheaterAnimation from 'hooks/useTheaterAnimation';
import VIDEO_ALPHA_MAP from 'assets/textures/alpha-map-earth-video.png';
import Mars from 'components/Mars';
import Sound from 'utils/sound';
import SKYBOX_TEXTURE from 'assets/textures/skybox-nebula.jpg';
import { shallow } from 'zustand/shallow';

const STEP_TIMELINES: { [step: number]: [number, number] } = {
  20: [0, 7],
  21: [7, 7.01],
  22: [7, 7.01],
  23: [7.01, 9],
  24: [9, 11],
  25: [11, 13],
  26: [13, 15]
};

interface EarthSceneProps {
  visible: boolean;
  videoTexture: VideoTexture;
}
function EarthScene({ visible, videoTexture }: EarthSceneProps) {
  const camera = useThree((s) => s.camera);
  const [hasEnteredAfterLoad, nextStep, previousStep, currentStep, direction, setShowOverlay] = useAppStore(
    (s) => [s.hasEnteredAfterLoad, s.nextStep, s.previousStep, s.currentStep, s.direction, s.setShowOverlay],
    shallow
  );

  const [earthMesh, setEarthMesh] = useState<Mesh | null>(null);

  useLookAroundCamera(camera as PerspectiveCamera, 0.1, 0.2, {
    disabled: !visible
  });

  const earthSequence = useTheaterAnimation('earthSequence', {
    onComplete: () => {
      switch (currentStep) {
        case 20:
          if (direction === 'normal') {
            setShowOverlay(true);
            nextStep();
          } else if (direction === 'reverse') {
            previousStep();
          }
          break;

        default:
          setShowOverlay(true);
      }
    },
    triggers: [
      {
        time: 3,
        timeReverse: 6,
        function: () => Sound.playSfxHyperSpeed()
      }
    ]
  });

  useEffect(() => {
    if (!visible || !hasEnteredAfterLoad || !STEP_TIMELINES[currentStep]) return;
    setShowOverlay(false);
    if (currentStep === 20) {
      if (direction === 'reverse') {
        earthSequence.play({ direction, range: STEP_TIMELINES[currentStep] });
        const transition = gsap.to('#transition', {
          opacity: 1.0,
          duration: 1.0,
          delay: 6.0,
          ease: Power2.easeInOut
        });
        return () => {
          transition.kill();
        };
      } else {
        earthSequence.play({ direction, range: STEP_TIMELINES[currentStep] });
        const transition = gsap.to('#transition', {
          opacity: 0.0,
          duration: 1.0,
          ease: Power2.easeInOut
        });
        return () => {
          transition.kill();
        };
      }
    }
    if (direction === 'normal' && STEP_TIMELINES[currentStep])
      earthSequence.play({ direction, range: STEP_TIMELINES[currentStep] });
    else if (STEP_TIMELINES[currentStep + 1]) earthSequence.play({ direction, range: STEP_TIMELINES[currentStep + 1] });
  }, [currentStep, hasEnteredAfterLoad]); // eslint-disable-line

  useFrame(() => {
    if (camera.parent) earthMesh?.lookAt(camera.parent?.position);
  });

  const videoAlphaMapTexture = useTexture(VIDEO_ALPHA_MAP);
  const skybox = useTexture(SKYBOX_TEXTURE);

  return (
    <group visible={visible}>
      <directionalLight
        color="white"
        rotation={[Math.PI / 8.0, Math.PI / 2.0, 0]}
        position={[5, 5, -5]}
        userData={{
          animated: true,
          animations: [{ sheetName: 'earthSequence', objectName: 'directionalLight' }]
        }}
        intensity={1.0}
      />
      <mesh visible={currentStep < 21} position={[0, 0, 100]} scale={[300, 300, 300]}>
        <sphereGeometry args={[1, 20, 20]}></sphereGeometry>
        <meshBasicMaterial
          map={skybox}
          transparent={true}
          side={THREE.BackSide}
          userData={{
            animated: true,
            animations: [{ objectName: 'skyboxMaterial', sheetName: 'earthSequence' }]
          }}
        />
      </mesh>
      <mesh
        position={[0, 0, 0]}
        scale={[100, 100 / (16 / 9), 1] as never}
        rotation={[0, 0, 0]}
        ref={setEarthMesh}
        userData={{
          animated: true,
          animations: [{ sheetName: 'earthSequence', objectName: 'earth' }]
        }}
      >
        <planeGeometry scale={[1, 1, 1] as never} />
        <meshBasicMaterial
          side={THREE.FrontSide}
          fog={false}
          transparent={true}
          alphaMap={videoAlphaMapTexture}
          map={videoTexture}
        />
      </mesh>
      <Mars />
    </group>
  );
}

export default memo(EarthScene);
