import SCREEN_9_ALL from 'assets/images/screen-9-all.png';
import SCREEN_13_ALL from 'assets/images/screen-13-all.png';
import SCREEN_14_ALL from 'assets/images/screen-14-all.png';
import SCREEN_26_CENTER from 'assets/images/screen-26-center.svg'; //
import SCREEN_26_ORANGE_EXPANDED from 'assets/images/screen-26-orange-expanded.svg';
import SCREEN_26_PURPLE_EXPANDED from 'assets/images/screen-26-purple-expanded.svg';
import SCREEN_26B_CENTER from 'assets/images/screen-26b-center.svg';
import SCREEN_26B_ORANGE_EXPANDED from 'assets/images/screen-26b-orange-expanded.svg';
import SCREEN_26B_PURPLE_EXPANDED from 'assets/images/screen-26b-purple-expanded.svg';
import SCREEN_26B_BLUE_EXPANDED from 'assets/images/screen-26b-blue-expanded.svg';
import SCREEN_27_ALL from 'assets/images/screen-27-all.png';
import SCREEN_29_CHART from 'assets/images/screen-29-chart.svg';
import SCREEN_42_ALL from 'assets/images/screen-42-all.png';
import SCREEN_44_ALL from 'assets/images/screen-44-all.png';
import SCREEN_75_ALL from 'assets/images/screen-75-all.png';
import SCREEN_76_ALL from 'assets/images/screen-76-all.png';
import SCREEN_77_ALL from 'assets/images/screen-77-all.png';
import SCREEN_78_ALL from 'assets/images/screen-78-all.png';
import SCREEN_82_ALL from 'assets/images/screen-82-all.png';
import DOWNLOAD from 'assets/icons/download.svg';
import TeamCarousel from 'components/TeamCarousel/TeamCarousel';
import { useEffect } from 'react';
import useAppStore from 'hooks/useAppStore';
import { gsap, Power2 } from 'gsap';
import Sound from 'utils/sound';
import { IScreenContent } from 'interfaces/interfaces';
import { shallow } from 'zustand/shallow';
import SolutionVideo from 'components/SolutionVideo/SolutionVideo';
import PeachAIScreen from 'components/PeachAIScreen/PeachAIScreen';
import CaseStudies from 'components/CaseStudies/CaseStudies';

export const screens: IScreenContent[] = [
  {
    content: null,
    figmaId: 4,
    isMenuItem: true,
    isOpportunityScreen: true,
    isTitleHidden: true,
    isWithSkipButton: true,
    step: 2,
    title: 'OPPORTUNITY',
    followingVoiceOver: Sound.screen4FollowingVoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex justify-center items-center">
        <img alt="stats" className="w-[90%] max-w-[1633px]" src={SCREEN_44_ALL} />
      </div>
    ),
    figmaId: 44,
    isMenuItem: true,
    step: 3,
    title: 'PROBLEM',
    voiceOver: Sound.screen44VoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex flex-col justify-center items-center">
        <SolutionVideo />
      </div>
    ),
    figmaId: 6,
    isMenuItem: true,
    step: 4,
    title: 'SOLUTION',
    isSolutionScreen: true
  },
  {
    content: (
      <div className="w-full h-full flex flex-col justify-center items-center">
        <img alt="stats" className="w-[90%] max-w-[1435px]" src={SCREEN_75_ALL} />
      </div>
    ),
    figmaId: 4,
    isMenuItem: true,
    step: 5,
    title: 'TECHNOLOGY',
    voiceOver: Sound.screen4VoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex justify-center items-center">
        <CaseStudies />
      </div>
    ),
    figmaId: 8,
    isMenuItem: true,
    step: 6,
    title: 'USE CASES FOR ‘WEB WORLDS’',
    voiceOver: Sound.screen8VoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex justify-center items-center">
        <img alt="stats" className="mr-[-36%] w-[90%] max-w-[1129px]" src={SCREEN_9_ALL} />
      </div>
    ),
    figmaId: 9,
    isMenuItem: true,
    step: 7,
    title: 'MARKET SIZE',
    voiceOver: Sound.screen9VoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex justify-center items-center">
        <img alt="all" className="w-[90%] max-w-[1435px]" src={SCREEN_27_ALL} />
      </div>
    ),
    figmaId: 27,
    isMenuItem: true,
    step: 8,
    title: 'COMPETITION',
    voiceOver: Sound.screen27VoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex justify-center items-center">
        <img alt="stats" className="w-[90%] max-w-[1392px] mr-[3%]" src={SCREEN_13_ALL} />
      </div>
    ),
    figmaId: 13,
    isMenuItem: true,
    step: 9,
    title: 'GO-TO MARKET',
    voiceOver: Sound.screen13VoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex justify-center items-center">
        <img alt="all" className="w-[90%] max-w-[1878px] ml-[15%] mt-[5%]" src={SCREEN_42_ALL} />
      </div>
    ),
    figmaId: 42,
    isMenuItem: true,
    step: 10,
    title: 'MARKETING STRATEGY',
    voiceOver: Sound.screen42VoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex justify-center items-center">
        <img alt="all" className="w-[90%] max-w-[2178px] mt-[15%]" src={SCREEN_14_ALL} />
      </div>
    ),
    figmaId: 14,
    isMenuItem: true,
    step: 11,
    title: 'BUSINESS MODEL',
    voiceOver: Sound.screen14VoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex justify-center items-center">
        <img alt="all" className="w-[90%] max-w-[1062px] mt-[-60%]" src={SCREEN_76_ALL} />
      </div>
    ),
    figmaId: 76,
    isMarsScreen: true,
    isMenuItem: true,
    isTitleHidden: true,
    step: 14,
    title: 'TRACTION',
    voiceOver: Sound.screen76VoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex justify-center items-center">
        <img alt="all" className="w-[90%] max-w-[1350px] ml-[-50%] mt-[-5%]" src={SCREEN_77_ALL} />
      </div>
    ),
    figmaId: 77,
    isMarsScreen: true,
    step: 15,
    title: 'TRACTION',
    voiceOver: Sound.screen77VoiceOver
  },
  // {
  //   content: (
  //     <div className="w-full h-full flex justify-center items-center">
  //       <img alt="all" className="w-[90%] max-w-[1200px] ml-[-50%] mt-[-5%]" src={SCREEN_78_ALL} />
  //     </div>
  //   ),
  //   figmaId: 78,
  //   isMarsScreen: true,
  //   step: 16,
  //   title: 'TRACTION',
  //   voiceOver: Sound.screen78VoiceOver
  // },
  // {
  //   content: (
  //     <div className="w-full h-full flex justify-center items-center">
  //       <img alt="all" className="w-[90%] max-w-[1700px]" src={SCREEN_82_ALL} />
  //     </div>
  //   ),
  //   figmaId: 82,
  //   isMarsScreen: true,
  //   isMenuItem: true,
  //   step: 17,
  //   title: 'ROADMAP',
  //   voiceOver: Sound.screen82VoiceOver
  // },
  {
    content: <PeachAIScreen />,
    figmaId: 83,
    isMarsScreen: true,
    isMenuItem: true,
    step: 18,
    title: 'VISION: PEACH AI',
    voiceOver: Sound.screen83VoiceOver
  },
  {
    content: null,
    figmaId: 21,
    isSloveniaScreen: true,
    isMenuItem: true,
    step: 21,
    title: 'INFRASTRUCTURE',
    voiceOver: Sound.screen21VoiceOver
  },
  {
    content: <TeamCarousel />,
    figmaId: 25,
    isMenuItem: true,
    isTeamScreen: true,
    step: 22,
    title: 'TEAM',
    voiceOver: Sound.screen25VoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex justify-center items-center">
        <img alt="all" className="w-[1082px]" src={SCREEN_29_CHART} />
      </div>
    ),
    figmaId: 29,
    isMenuItem: true,
    step: 23,
    title: 'FINANCIALS',
    voiceOver: Sound.screen29VoiceOver
  },
  // {
  //   content: (
  //     <div className="w-full h-full flex justify-center items-center">
  //       <img alt="all" className="ml-[-50%]" src={SCREEN_26_CENTER} />
  //       <>
  //         <div
  //           className="absolute mb-[490px] ml-[245px] w-[300px] h-[150px] peer/1"
  //           onMouseEnter={() => Sound.playSfxHover()}
  //         />
  //         <div
  //           className="absolute mb-[80px] ml-[500px] w-[150px] h-[400px] peer/1"
  //           onMouseEnter={() => Sound.playSfxHover()}
  //         />
  //         <div
  //           className="absolute mt-[465px] mr-[5px] w-[500px] h-[150px] peer/1"
  //           onMouseEnter={() => Sound.playSfxHover()}
  //         />
  //         <div
  //           className="absolute mb-[80px] mr-[500px] w-[150px] h-[400px] peer/1"
  //           onMouseEnter={() => Sound.playSfxHover()}
  //         />
  //         <img
  //           alt="all"
  //           className="absolute mt-[340px] ml-[1240px] opacity-0 peer-hover/1:opacity-100 pointer-events-none transition-opacity duration-400"
  //           src={SCREEN_26_ORANGE_EXPANDED}
  //         />
  //       </>
  //       <>
  //         <div
  //           className="absolute mb-[530px] mr-[240px] w-[180px] h-[110px] peer/2"
  //           onMouseEnter={() => Sound.playSfxHover()}
  //         />
  //         <img
  //           alt="all"
  //           className="absolute mt-[-310px] mr-[910px] opacity-0 peer-hover/2:opacity-100 pointer-events-none transition-opacity duration-400"
  //           src={SCREEN_26_PURPLE_EXPANDED}
  //         />
  //       </>
  //     </div>
  //   ),
  //   figmaId: 26,
  //   isMenuItem: true,
  //   step: 24,
  //   title: 'PRE-SEED INVESTMENT',
  //   voiceOver: Sound.screen26VoiceOver
  // },
  {
    content: (
      <div className="w-full h-full flex justify-center items-center">
        <img alt="all" src={SCREEN_26B_CENTER} />
        <>
          <div
            className="absolute mb-[400px] ml-[295px] w-[300px] h-[150px] peer/1"
            onMouseEnter={() => Sound.playSfxHover()}
          />
          <div
            className="absolute mb-[1px] ml-[500px] w-[150px] h-[400px] peer/1"
            onMouseEnter={() => Sound.playSfxHover()}
          />
          <div
            className="absolute mt-[405px] mr-[5px] w-[500px] h-[150px] peer/1"
            onMouseEnter={() => Sound.playSfxHover()}
          />
          <img
            alt="all"
            className="absolute mt-[330px] ml-[1200px] opacity-0 peer-hover/1:opacity-100 pointer-events-none transition-opacity duration-400"
            src={SCREEN_26B_ORANGE_EXPANDED}
          />
        </>
        <>
          <div
            className="absolute mb-[60px] mr-[380px] w-[180px] h-[420px] peer/2"
            onMouseEnter={() => Sound.playSfxHover()}
          />
          <img
            alt="all"
            className="absolute mt-[180px] mr-[990px] opacity-0 peer-hover/2:opacity-100 pointer-events-none transition-opacity duration-400"
            src={SCREEN_26B_PURPLE_EXPANDED}
          />
        </>
        <>
          <div
            className="absolute mb-[480px] mr-[100px] w-[120px] h-[90px] peer/3"
            onMouseEnter={() => Sound.playSfxHover()}
          />
          <img
            alt="all"
            className="absolute mt-[-500px] mr-[770px] opacity-0 peer-hover/3:opacity-100 pointer-events-none transition-opacity duration-400"
            src={SCREEN_26B_BLUE_EXPANDED}
          />
        </>
      </div>
    ),
    figmaId: '26b',
    isMenuItem: true,
    step: 25,
    title: 'SEED INVESTMENT',
    voiceOver: Sound.screen26BVoiceOver
  },
  {
    content: (
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="relative justify-center text-center">
          <span className="text-ln text-white gradiend-underline">WANT TO EMBARK ON A JOURNEY?</span>
          <br />
          <br />
          <br />
          <span className="mt-50 text-h3 whitespace-nowrap text-white">
            LET’S CHAT! EMAIL <a href="mailto:lucas@peachworlds.com">LUCAS@PEACHWORLDS.COM</a>
          </span>
        </div>
        <a
          className="mt-[60px] w-[250px] border border-white p-4 flex place-content-center active:scale-90 orange-corners-button "
          download="Peach Worlds - Investor Deck.pdf"
          href="./peach-worlds.pdf"
          onMouseDown={() => Sound.playSfxClick()}
          onMouseEnter={() => Sound.playSfxHover()}
        >
          <img alt="all" className="w-[28px]" src={DOWNLOAD} />
          <span className="text-b text-white ml-4">PDF DECK</span>
        </a>
      </div>
    ),
    figmaId: 999,
    isMenuItem: true,
    step: 26,
    title: 'CONTACT',
    voiceOver: Sound.screen999VoiceOver,
    isLastScreen: true
  }
];

const FADE_IN_ANIMATION = (onComplete?: () => void) => {
  gsap.to('#screen-content', {
    opacity: 1.0,
    scale: 1.0,
    duration: 1.2,
    ease: Power2.easeInOut,
    onComplete: onComplete
  });
};

const FADE_OUT_ANIMATION = (onComplete?: () => void) => {
  gsap.to('#screen-content', {
    opacity: 0.0,
    scale: 0.5,
    duration: 0.3,
    ease: Power2.easeInOut,
    onComplete: onComplete
  });
};

export default function ScreenContent() {
  const [currentScreen, currentStep, showOverlay, setCurrentScreen] = useAppStore(
    (s) => [s.currentScreen, s.currentStep, s.showOverlay, s.setCurrentScreen],
    shallow
  );

  useEffect(() => {
    if (showOverlay) {
      FADE_IN_ANIMATION(() => setCurrentScreen(screens.find((s) => s.step === currentStep)));
    } else {
      FADE_OUT_ANIMATION(() => setCurrentScreen(screens.find((s) => s.step === currentStep)));
    }
  }, [currentStep, showOverlay]); // eslint-disable-line

  return (
    <div className="select-none" id="screen-content">
      {currentScreen?.content}
    </div>
  );
}
