import { memo, useEffect } from 'react';
import { useAnimations, useGLTF, useTexture } from '@react-three/drei';
import { PerspectiveCamera, BackSide } from 'three';
import { HologramPlanet } from 'components/HologramPlanet/HologramPlanet';
import useLookAroundCamera from 'hooks/useLookAroundCamera';
import useTheaterAnimation from 'hooks/useTheaterAnimation';
import { useThree } from '@react-three/fiber';
import Mars from 'components/Mars';
import useAppStore from 'hooks/useAppStore';
import { gsap, Power2 } from 'gsap';
import SKYBOX_TEXTURE from 'assets/textures/skybox-nebula.jpg';
import RoadToSpaceStation from 'components/RainbowRoad/RoadToSpaceStation';
import Sound from 'utils/sound';
import RoadAroundMars from 'components/RainbowRoad/RoadAroundMars';
import { Asteroids } from 'components/Asteroids/Asteroids';
import SPACESTATION_MODEL from 'assets/models/spacestation.glb';
import { shallow } from 'zustand/shallow';

const STEP_TIMELINES: { [step: number]: { range: [number, number]; showOnlyOnMenuNavigation?: boolean } } = {
  2: { range: [7 + 8 / 30, 7 + 9 / 30] },
  3: { range: [7 + 8 / 30, 7 + 9 / 30], showOnlyOnMenuNavigation: true },
  4: { range: [7 + 8 / 30, 7 + 9 / 30], showOnlyOnMenuNavigation: true },
  5: { range: [7 + 8 / 30, 7 + 9 / 30], showOnlyOnMenuNavigation: true },
  6: { range: [14.5, 25 + 5 / 30] },
  7: { range: [25 + 5 / 30, 26 + 24 / 30] },
  8: { range: [26 + 24 / 30, 28 + 24 / 30] },
  9: { range: [28 + 24 / 30, 34 + 24 / 30] },
  10: { range: [34.8, 34.8 + 1 / 30.0], showOnlyOnMenuNavigation: true },
  11: { range: [34.8, 34.8 + 1 / 30.0], showOnlyOnMenuNavigation: true },
  12: { range: [34.8, 34.8 + 1 / 30.0], showOnlyOnMenuNavigation: true }
};

function SpacestationScene({ visible }: { visible: boolean }) {
  const camera = useThree((s) => s.camera);

  const [hasEnteredAfterLoad, currentStep, direction, showOverlay, setShowOverlay, menuNavigation] = useAppStore(
    (state) => [
      state.hasEnteredAfterLoad,
      state.currentStep,
      state.direction,
      state.showOverlay,
      state.setShowOverlay,
      state.menuNavigation
    ],
    shallow
  );

  const spaceSequence = useTheaterAnimation('spaceSequence', {
    onComplete: () => {
      if ((5 < currentStep && currentStep < 9) || (currentStep === 5 && direction === 'reverse')) setShowOverlay(true);
    },
    triggers: [
      {
        time: 30,
        timeReverse: 33.5,
        function: () => Sound.playSfxHyperSpeed()
      }
    ]
  });

  useLookAroundCamera(camera as PerspectiveCamera, 0.1, 0.05, {
    disabled: !visible || !showOverlay
  });

  useEffect(() => {
    if (currentStep === 2 && direction === 'normal') {
      spaceSequence.play({ range: [7 + 8 / 30, 7 + 9 / 30] });
      camera.rotation.set(0, 0, 0);
      setShowOverlay(true);
      gsap.fromTo(
        '#transition',
        { opacity: 1.0 },
        {
          opacity: 0.0,
          delay: 2.0,
          duration: 1.0,
          ease: Power2.easeInOut
        }
      );
      return;
    }
    if (
      !visible ||
      !hasEnteredAfterLoad ||
      (currentStep === 11 && direction === 'reverse') ||
      (direction === 'normal' && !STEP_TIMELINES[currentStep]) ||
      (direction === 'reverse' && !STEP_TIMELINES[currentStep + 1])
    )
      return;
    setShowOverlay(false);

    if (direction === 'normal') {
      if (menuNavigation || !STEP_TIMELINES[currentStep].showOnlyOnMenuNavigation)
        spaceSequence.play({ direction, range: STEP_TIMELINES[currentStep].range });
    } else if (currentStep === 8) {
      if (menuNavigation || !STEP_TIMELINES[currentStep + 1].showOnlyOnMenuNavigation)
        spaceSequence.play({ direction, range: STEP_TIMELINES[currentStep + 1].range, delay: 4.6 });
    } else {
      if (menuNavigation || !STEP_TIMELINES[currentStep + 1].showOnlyOnMenuNavigation)
        spaceSequence.play({ direction, range: STEP_TIMELINES[currentStep + 1].range });
    }
  }, [currentStep, hasEnteredAfterLoad]); // eslint-disable-line

  const skybox = useTexture(SKYBOX_TEXTURE);
  const spaceStation = useGLTF(SPACESTATION_MODEL);
  const spaceStationAnimations = useAnimations(spaceStation.animations, spaceStation.scene);

  useEffect(() => {
    spaceStationAnimations.names.forEach((name: string) => spaceStationAnimations.actions[name]?.play());
  }, [spaceStation, spaceStationAnimations.actions, spaceStationAnimations.names]);

  return (
    <group visible={visible} userData={{ isSpaceStationScene: true }}>
      <directionalLight
        color="white"
        rotation={[Math.PI / 8.0, Math.PI / 2.0, 0]}
        position={[5, 5, -5]}
        userData={{ animated: true, animations: [{ objectName: 'light', sheetName: 'lights' }] }}
        intensity={1.0}
      />
      <mesh visible={currentStep > 7} position={[0, 0, 100]} scale={[300, 300, 300]}>
        <sphereGeometry args={[1, 20, 20]}></sphereGeometry>
        <meshBasicMaterial
          map={skybox}
          transparent={currentStep < 10}
          side={BackSide}
          userData={{
            animated: true,
            animations: [{ objectName: 'skyboxMaterial', sheetName: 'spaceSequence' }]
          }}
        />
      </mesh>
      <RoadToSpaceStation />
      <RoadAroundMars />
      <Asteroids name="asteroids" />
      <Asteroids name="asteroids2" />
      <HologramPlanet />
      <Mars />
      <group
        userData={{
          animated: true,
          animations: [{ objectName: 'spaceStation', sheetName: 'spaceSequence' }]
        }}
      >
        <primitive object={spaceStation.scene}></primitive>
      </group>
    </group>
  );
}

export default memo(SpacestationScene);
