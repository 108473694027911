import React, { useEffect, useRef } from 'react';
import { Object3D, Vector3 } from 'three';
import CUSTOM_CURSOR from 'assets/icons/rotate-cursor.png';
import useAppStore from 'hooks/useAppStore';

export default function Robot({ robot }: { robot: Object3D }) {
  const dragging = useRef(false);
  const currentStep = useAppStore((s) => s.currentStep);
  const previousTouch = useRef<number | undefined>();

  useEffect(() => {
    if (currentStep !== 14) return;
    const onMouseMove = (event: MouseEvent) => {
      if (dragging.current) {
        robot?.rotateOnAxis(new Vector3(0, 0, event.movementX > 0 ? -1 : 1), Math.PI / 60);
      }
    };

    const onMouseDown = (event: MouseEvent) => {
      dragging.current = true;
      document.body.style.cursor = `url(${CUSTOM_CURSOR}), auto`;
    };
    const onMouseUp = (event: MouseEvent) => {
      dragging.current = false;
      document.body.style.cursor = 'auto';
    };

    const onTouchMove = (event: TouchEvent) => {
      if (dragging.current) {
        if (!previousTouch.current) previousTouch.current = event.targetTouches[0].screenX;
        else {
          robot?.rotateOnAxis(
            new Vector3(0, 0, event.targetTouches[0].screenX - previousTouch.current > 0 ? -1 : 1),
            Math.PI / 60
          );
        }
      }
    };

    const onTouchStart = (event: MouseEvent | TouchEvent) => {
      dragging.current = true;
      document.body.style.cursor = `url(${CUSTOM_CURSOR}), auto`;
    };

    const onTouchEnd = (event: MouseEvent | TouchEvent) => {
      dragging.current = false;
      previousTouch.current = undefined;
      document.body.style.cursor = 'auto';
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchend', onTouchEnd);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mouseup', onMouseUp);
      window.addEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchend', onTouchEnd);
    };
  }, [currentStep, robot]);

  return (
    <group
      userData={{
        animated: true,
        animations: [{ objectName: 'rover', sheetName: 'lights' }]
      }}
    >
      <mesh
        position={[0, 0, -1]}
        onPointerEnter={() => {
          if (currentStep === 14) document.body.style.cursor = `url(${CUSTOM_CURSOR}), auto`;
        }}
        onPointerOut={() => {
          if (!dragging.current) document.body.style.cursor = 'auto';
        }}
      >
        <sphereGeometry args={[1.5, 12, 12]} />
        <meshBasicMaterial transparent opacity={0.0} />
      </mesh>
      <rectAreaLight position={[0, 0, 0]} args={['#ced3ff', 5.0, 5, 5]} />
    </group>
  );
}
