import { useEffect } from 'react';
import { MathUtils, PerspectiveCamera, Vector3 } from 'three';
import { gsap } from 'gsap';

interface LookAroundCameraOptions {
  disabled?: boolean;
  zoomOptions?: {
    speed: number;
    minFOV: number;
    maxFOV: number;
  };
}

export default function useLookAroundCamera(
  camera: PerspectiveCamera,
  turnSpeed: number,
  turnRadius: number,
  options?: LookAroundCameraOptions
) {
  useEffect(() => {
    const onMouseMove = (event: MouseEvent) => {
      if (options?.disabled) return;
      const normalizedMousePosition: Vector3 = new Vector3(event.y / window.innerHeight, event.x / window.innerWidth, 0)
        .multiplyScalar(2)
        .subScalar(1)
        .multiplyScalar(-turnRadius);

      gsap.to(camera.rotation, {
        x: normalizedMousePosition.x,
        y: normalizedMousePosition.y,
        duration: turnSpeed
      });
    };
    const onWheel = (event: WheelEvent) => {
      if (!options?.zoomOptions || options.disabled) return;
      const { zoomOptions } = options;
      gsap.to(camera, {
        fov: MathUtils.clamp(camera.fov + event.deltaY * zoomOptions.speed, zoomOptions.minFOV, zoomOptions.maxFOV),
        duration: turnSpeed,
        onUpdate: () => {
          camera.updateProjectionMatrix();
        }
      });
    };

    window.addEventListener('mousemove', onMouseMove);

    if (!options?.zoomOptions && !options?.disabled)
      gsap.to(camera, {
        fov: 50,
        duration: 1.0,
        onUpdate: () => {
          camera.updateProjectionMatrix();
        }
      });
    if (options?.zoomOptions) {
      if (!options?.disabled && camera.fov < options.zoomOptions?.minFOV) {
        gsap.to(camera, {
          fov: options?.zoomOptions?.minFOV,
          duration: 1.0,
          onUpdate: () => {
            camera.updateProjectionMatrix();
          }
        });
      }
      window.addEventListener('wheel', onWheel);
    }
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('wheel', onWheel);
    };
  }, [camera, options, turnRadius, turnSpeed]);
}
