import { Canvas } from '@react-three/fiber';
import SLOVENIA_VIDEO from 'assets/videos/pw-slovenia.mp4';
import SCREEN_21_ALL from 'assets/images/screen-21-all.png';
import EnterScreen from 'components/EnterScreen/EnterScreen';
import Renderer from 'components/Renderer';
import { useEffect, useMemo, useRef } from 'react';
import { gsap, Power2 } from 'gsap';
import { useTheaterContext, AnimationContext } from 'hooks/useTheaterContext';
import ScreenBase from 'components/ScreenBase/ScreenBase';
import useAppStore from 'hooks/useAppStore';
import CodeScreen from 'components/CodeScreen/CodeScreen';
import MuteButton from 'components/MuteButton/MuteButton';
import CloseButton from 'components/CloseButton/CloseButton';
import MenuButton from 'components/MenuButton/MenuButton';
import OpportunityVideo from 'components/OpportunityVideo/OpportunityVideo';
import LOGO_URL from 'assets/images/peach-worlds-logo.png';
import Sound from 'utils/sound';
import { Perf } from 'r3f-perf';
import { IS_DEVELOPMENT } from 'utils/utils';
import { shallow } from 'zustand/shallow';

gsap.defaults({
  overwrite: true
});

export default function MainCanvas() {
  const { project, setProjectConfig } = useTheaterContext();
  const [
    currentScreen,
    currentTeamMember,
    hasEnteredAfterLoad,
    currentStep,
    showOverlay,
    setHasEnteredAfterLoad,
    nextStep,
    setCurrentStep,
    setCurrentTeamMember,
  ] = useAppStore(
    (s) => [
      s.currentScreen,
      s.currentTeamMember,
      s.hasEnteredAfterLoad,
      s.currentStep,
      s.showOverlay,
      s.setHasEnteredAfterLoad,
      s.nextStep,
      s.setCurrentStep,
      s.setCurrentTeamMember,
      s.setQuality
    ],
    shallow
  );

  useEffect(() => {
    if (hasEnteredAfterLoad && currentStep === 0) {
      Sound.play(Sound.screenStarshipVoiceOver);
    }
    return () => Sound.pause(Sound.screenStarshipVoiceOver);
  }, [currentStep, hasEnteredAfterLoad]);

  const sloveniaVideoRef = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    if (!sloveniaVideoRef.current) return;

    if (currentStep === 21) sloveniaVideoRef.current.play();
    else sloveniaVideoRef.current.pause();
  }, [currentStep]);

  const animationContext = useMemo(() => ({ project, setProjectConfig }), [project, setProjectConfig]);

  return (
    <AnimationContext.Provider value={animationContext}>
      <Canvas
        gl={{ alpha: false, preserveDrawingBuffer: false, powerPreference: 'high-performance' }}
        style={currentStep === 1 ? { filter: 'blur(10px)', opacity: 0.5 } : {}}
      >
        {IS_DEVELOPMENT && <Perf position={'top-left'} />}
        <Renderer />
      </Canvas>

      {/* opportunity screen (with video background) */}
      <div
        className={`
          z-10 bg-[#000000] top-0 left-0 absolute w-full h-full duration-1000 ease-in transition-all  flex justify-center items-center
          ${currentScreen?.isOpportunityScreen && showOverlay ? 'opacity-1' : 'opacity-0 pointer-events-none'}
        `}
      >
        <OpportunityVideo />
      </div>

      {/* infrastructure screen (with slovenia video background) */}
      <div
        className={`
          z-10 bg-[#000000] top-0 left-0 absolute w-full h-full duration-1000 ease-in transition-all 
          ${currentScreen?.isSloveniaScreen && showOverlay ? 'opacity-1' : 'opacity-0 pointer-events-none'}
        `}
      >
        <video ref={sloveniaVideoRef} className="w-full h-full object-cover object-center" loop muted playsInline>
          <source src={SLOVENIA_VIDEO} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 w-[70%] h-full bg-gradient-slovenia"></div>
        <img alt="all" className="absolute top-[30%] left-[10%] w-[50%] max-w-[1139px] " src={SCREEN_21_ALL} />
      </div>

      {/* very first screen with logo */}
      {currentStep === 0 && (
        <div className="absolute w-full bottom-[95px] flex flex-col justify-center items-center">
          {/* <img alt="peach studios logo" className="px-[20px] md:px-0 " src={LOGO_IMAGE_URL}></img> */}
          <button
            className="mt-[12px] border-solid  border-[#ffffff]/30 tracking-widest px-[46px] py-[18px] border-[1px] leading-[42px] text-[24px] md:text-[32px] active:scale-90 hover:text-orange text-[#FFFFFF] orange-corners-button pointer-events-auto "
            onClick={nextStep}
            onMouseDown={() => Sound.playSfxClick()}
            onMouseEnter={() => Sound.playSfxHover()}
          >
            GET STARTED
          </button>
        </div>
      )}
      <EnterScreen enter={() => setHasEnteredAfterLoad(true)} />

      {/* code screen */}
      {hasEnteredAfterLoad && currentStep === 1 && (
        <CodeScreen
          onStart={() => {
            nextStep();
            gsap
              .fromTo(
                '#transition',
                {
                  opacity: 1.0
                },
                {
                  opacity: 0.0,
                  duration: 1.0,
                  ease: Power2.easeInOut
                }
              )
              .play();
          }}
          onClose={() => setCurrentStep(0)}
        />
      )}

      {/* screens */}
      {hasEnteredAfterLoad && currentStep > 1 && <ScreenBase />}
      <div id="transition" className="w-full pointer-events-none h-full absolute left-0 top-0 bg-[#000000] opacity-0" />

      {/* corner logo */}
      {hasEnteredAfterLoad && (
        <img alt="peach studios logo" className="absolute top-4 left-8 z-10 w-[300px]" src={LOGO_URL} />
      )}

      {/* top right circle buttons */}
      {hasEnteredAfterLoad && <MuteButton isOnlyIcon={currentStep === 0} />}
      {currentStep > 1 && !currentTeamMember && <MenuButton />}
      {currentScreen?.isTeamScreen && !!currentTeamMember && <CloseButton onClose={() => setCurrentTeamMember(null)} />}
    </AnimationContext.Provider>
  );
}
