import SCREEN_25_ALEX from 'assets/images/screen-25-alex.png';
import SCREEN_25_ALEX_EXPANDED from 'assets/images/screen-25-alex-expanded.png';
import SCREEN_25_ANDRAZ from 'assets/images/screen-25-andraz.png';
import SCREEN_25_ANTON from 'assets/images/screen-25-anton.png';
import SCREEN_25_CONNOR from 'assets/images/screen-25-connor.png';
import SCREEN_25_CONNOR_EXPANDED from 'assets/images/screen-25-connor-expanded.png';
import SCREEN_25_GREGA from 'assets/images/screen-25-grega.png';
import SCREEN_25_EMILIJA from 'assets/images/screen-25-emilija.png';
import SCREEN_25_GREGA_EXPANDED from 'assets/images/screen-25-grega-expanded.png';
import SCREEN_25_GORAZD from 'assets/images/screen-25-gorazd.png';
import SCREEN_25_LUCAS_EXPANDED from 'assets/images/screen-25-lucas-expanded.png';
import SCREEN_25_MILICA from 'assets/images/screen-25-milica.png';
import SCREEN_25_JAN_2 from 'assets/images/screen-25-jan-2.png';
import SCREEN_25_JAN_2_EXPANDED from 'assets/images/screen-25-jan-2-expanded.png';
import SCREEN_25_JASA from 'assets/images/screen-25-jasa.png';
import SCREEN_25_LIZA from 'assets/images/screen-25-liza.png';
import SCREEN_25_LUCAS from 'assets/images/screen-25-lucas.png';
import SCREEN_25_MARK from 'assets/images/screen-25-mark.png';
import SCREEN_25_MARK_EXPANDED from 'assets/images/screen-25-mark-expanded.png';
import SCREEN_25_OMER from 'assets/images/screen-25-omer.png';
import SCREEN_25_OMER_EXPANDED from 'assets/images/screen-25-omer-expanded.png';
import SCREEN_25_PRIMOZ from 'assets/images/screen-25-primoz.png';
import SCREEN_25_TADEJ from 'assets/images/screen-25-tadej.png';
import SCREEN_25_RUSTEM from 'assets/images/screen-25-rustem.png';
import ARROW_LEFT_HIGH_ICON from 'assets/icons/arrow-left-high.svg';
import './TeamCarousel.scss';
import { useLayoutEffect, useRef, useState } from 'react';
import useAppStore from 'hooks/useAppStore';
import Sound from 'utils/sound';

function TeamCarouselItem({ onClick, src, parentVisibleRange }: { onClick?: any; src: any; parentVisibleRange?: any }) {
  const ref: any = useRef();
  const isVisible =
    parentVisibleRange.from < ref?.current?.offsetLeft && parentVisibleRange.to > ref?.current?.offsetLeft + 300;

  return (
    <img
      alt="stats"
      className={`
        w-[300px] mr-8 duration-500 transition-all
        ${!isVisible ? 'opacity-30' : 'opacity-100'}
        ${onClick ? 'cursor-pointer hover:scale-90 duration-500 ease-out' : ''}
      `}
      onClick={onClick || undefined}
      onMouseDown={() => Sound.playSfxClick()}
      onMouseEnter={() => Sound.playSfxHover()}
      ref={ref}
      src={src}
    />
  );
}

export default function TeamCarousel() {
  const ref: any = useRef();
  const currentTeamMember = useAppStore((s) => s.currentTeamMember);
  const setCurrentTeamMember = useAppStore((s) => s.setCurrentTeamMember);
  const [currentTeamMemberLink, setCurrentTeamMemberLink] = useState<null | string>(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const onArrowClick = (isMoveRight: boolean) => {
    ref?.current?.scrollTo({ left: ref?.current?.scrollLeft + (isMoveRight ? 1400 : -1400), behavior: 'smooth' });
  };

  const onScroll = () => {
    setScrollLeft(ref?.current?.scrollLeft);
  };

  const onTeamMembereSelect = (imgSrc: string, link?: string) => {
    setCurrentTeamMember(imgSrc);
    setCurrentTeamMemberLink(link || null);
  };

  useLayoutEffect(() => {
    onScroll();
    ref?.current?.addEventListener('scroll', onScroll);
    setTimeout(() => ref?.current?.scrollTo({ left: 1 }), 500);

    return () => {
      ref?.current?.removeEventListener('scroll', onScroll); // eslint-disable-line
    };
  }, []);

  const parentVisibleRange = { from: scrollLeft, to: scrollLeft + ref?.current?.offsetWidth };

  return (
    <div className="w-full h-full flex justify-center items-center pointer-events-auto">
      <img
        alt="active-member"
        className={`
          absolute z-10 w-[1200px] transition-opacity duration-400
          ${currentTeamMemberLink ? ' cursor-pointer' : ''}
          ${currentTeamMember ? ' opacity-100' : ' opacity-0 pointer-events-none'}
        `}
        onClick={() => currentTeamMemberLink && window.open(currentTeamMemberLink, '_blank')}
        src={currentTeamMember || SCREEN_25_GREGA_EXPANDED}
      />
      <div
        className={`w-full h-full flex justify-center items-center transition-opacity duration-400 ${
          currentTeamMember ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <img
          alt="arrow-left-high"
          className={`absolute left-[-40px] mb-12 z-10 ${scrollLeft > 1 ? 'cursor-pointer' : 'opacity-30'}`}
          onClick={() => onArrowClick(false)}
          onMouseDown={() => Sound.playSfxClick()}
          src={ARROW_LEFT_HIGH_ICON}
        />
        <img
          alt="arrow-left-high"
          className={`absolute right-[-40px] mb-12 rotate-180 z-10 ${
            scrollLeft + ref?.current?.offsetWidth < ref?.current?.scrollWidth ? 'cursor-pointer' : 'opacity-30'
          }`}
          onClick={() => onArrowClick(true)}
          onMouseDown={() => Sound.playSfxClick()}
          src={ARROW_LEFT_HIGH_ICON}
        />
        <div className="flex flex-col items-start">
          <div className="flex mb-8 pb-5 max-w-full scroll-custom" ref={ref}>
            <TeamCarouselItem
              parentVisibleRange={parentVisibleRange}
              src={SCREEN_25_GREGA}
              onClick={() =>
                onTeamMembereSelect(SCREEN_25_GREGA_EXPANDED, 'https://www.linkedin.com/in/grega-trobec-05386371/')
              }
            />
            <TeamCarouselItem
              parentVisibleRange={parentVisibleRange}
              src={SCREEN_25_LUCAS}
              onClick={() =>
                onTeamMembereSelect(SCREEN_25_LUCAS_EXPANDED, 'https://www.linkedin.com/in/lucas-poelman-5a729583/')
              }
            />
            <TeamCarouselItem
              parentVisibleRange={parentVisibleRange}
              src={SCREEN_25_ALEX}
              onClick={() => onTeamMembereSelect(SCREEN_25_ALEX_EXPANDED, 'https://www.linkedin.com/in/alexjmerritt/')}
            />
            <TeamCarouselItem
              parentVisibleRange={parentVisibleRange}
              src={SCREEN_25_MARK}
              onClick={() => onTeamMembereSelect(SCREEN_25_MARK_EXPANDED)}
            />
            <TeamCarouselItem
              parentVisibleRange={parentVisibleRange}
              src={SCREEN_25_CONNOR}
              onClick={() =>
                onTeamMembereSelect(SCREEN_25_CONNOR_EXPANDED, 'https://www.linkedin.com/in/connor-gregory-94691b5b/')
              }
            />
            <TeamCarouselItem
              parentVisibleRange={parentVisibleRange}
              src={SCREEN_25_JAN_2}
              onClick={() => onTeamMembereSelect(SCREEN_25_JAN_2_EXPANDED, 'https://www.linkedin.com/in/jan-ravnik/')}
            />
            <TeamCarouselItem
              parentVisibleRange={parentVisibleRange}
              src={SCREEN_25_OMER}
              onClick={() => onTeamMembereSelect(SCREEN_25_OMER_EXPANDED, 'https://www.linkedin.com/in/omer-shaikh/')}
            />
            <TeamCarouselItem parentVisibleRange={parentVisibleRange} src={SCREEN_25_EMILIJA} />
            <TeamCarouselItem parentVisibleRange={parentVisibleRange} src={SCREEN_25_LIZA} />
            <TeamCarouselItem parentVisibleRange={parentVisibleRange} src={SCREEN_25_MILICA} />
            <TeamCarouselItem parentVisibleRange={parentVisibleRange} src={SCREEN_25_TADEJ} />
            <TeamCarouselItem parentVisibleRange={parentVisibleRange} src={SCREEN_25_PRIMOZ} />
            <TeamCarouselItem parentVisibleRange={parentVisibleRange} src={SCREEN_25_RUSTEM} />
            <TeamCarouselItem parentVisibleRange={parentVisibleRange} src={SCREEN_25_GORAZD} />
            <TeamCarouselItem parentVisibleRange={parentVisibleRange} src={SCREEN_25_ANTON} />
            <TeamCarouselItem parentVisibleRange={parentVisibleRange} src={SCREEN_25_ANDRAZ} />
            <TeamCarouselItem parentVisibleRange={parentVisibleRange} src={SCREEN_25_JASA} />
          </div>
        </div>
      </div>
    </div>
  );
}
