import OPPORTUNITY_VIDEO from 'assets/videos/pw-opportunity.mp4';
import useAppStore from 'hooks/useAppStore';
import { useEffect, useRef } from 'react';

export default function OpportunityVideo() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const currentScreen = useAppStore((s) => s.currentScreen);
  const nextStep = useAppStore((s) => s.nextStep);
  const showOverlay = useAppStore((s) => s.showOverlay);

  const isOpportunityScreen = currentScreen?.isOpportunityScreen;
  useEffect(() => {
    setTimeout(() => {
      if (!videoRef.current) return;
      if (isOpportunityScreen && showOverlay) videoRef.current.play();
      else videoRef.current.pause();
    }, 500);
  }, [isOpportunityScreen, showOverlay]);

  useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.onended = () => {
      nextStep();
    };
  }, [videoRef]);

  return (
    <video autoPlay className="max-w-full max-h-full" controls ref={videoRef}>
      <source src={OPPORTUNITY_VIDEO} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}
