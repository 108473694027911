import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import Sound from 'utils/sound';
import useActiveTabDetector from 'hooks/useActiveTabDetector';
import useAppStore from 'hooks/useAppStore';
import usePreviousValue from 'hooks/usePreviousValue';
import { shallow } from 'zustand/shallow';

function Line({ index, paused }: { index: number; paused: boolean }) {
  const scale = (1 - Math.abs(index - 2) / 2) * 2 + 1;
  const ref = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<gsap.core.Tween>();

  useEffect(() => {
    animation && animation?.kill();
    const gsapAnimation = paused
      ? gsap.to(ref.current, {
          height: '5px',
          duration: '0.1'
        })
      : gsap.to(ref.current, {
          height: `random(5, ${scale * 22 + 10})`,
          duration: '0.3',
          repeat: -1,
          ease: 'none',
          yoyoEase: true,
          repeatRefresh: true
        });
    setAnimation(gsapAnimation);

    return () => {
      animation?.kill();
      setAnimation(undefined);
    };
  }, [paused]); // eslint-disable-line

  return (
    <div
      id={`audio-line-${index}`}
      className={`border-[white] group-hover:border-[orange] bg-[white] group-hover:bg-[orange] border-[1px] w-[3px] h-[5px] rounded-full transition-all`}
      ref={ref}
    />
  );
}

export default function MuteButton({ isOnlyIcon }: { isOnlyIcon: boolean }) {
  const { isTabActive } = useActiveTabDetector();
  const [currentScreen, currentStep, showOverlay] = useAppStore(
    (s) => [s.currentScreen, s.currentStep, s.showOverlay],
    shallow
  );
  const [isMuted2, setIsMuted2] = useState(Sound.getIsMuted());
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSlovenianMusicPlayed, setIsSlovenianMusicPlayed] = useState(false);

  const isSloveniaScreen = !!currentScreen?.isSloveniaScreen;
  const isLastScreen = !!currentScreen?.isLastScreen;
  const isMarsScreen = !!currentScreen?.isMarsScreen;

  useEffect(() => {
    if (currentStep <= 1 || currentStep >= 3) Sound.playOST(currentStep <= 1 ? 0 : 1);
    else Sound.pauseOST();
  }, [currentStep]); // eslint-disable-line

  // special OST for Ljubljana screen
  useEffect(() => {
    if (isSloveniaScreen && !isSlovenianMusicPlayed) {
      Sound.playOSTSlovenia();
      setIsSlovenianMusicPlayed(true);
    }
  }, [isSloveniaScreen]); // eslint-disable-line

  // special OST for last screen
  useEffect(() => {
    if (isLastScreen) {
      Sound.playOSTLastScreen();
    }
  }, [isLastScreen]);

  // for voice over - shouldn't be playing during switching between screens
  const currentScreenVoiceOver = currentScreen?.voiceOver;
  const [prevScreenVoiceOver, setPrevScreenVoiceOver] = useState<any>();
  useEffect(() => {
    if (!showOverlay || currentScreenVoiceOver !== prevScreenVoiceOver) {
      Sound.pauseAllVoiceOverAudios();
    }
    if (showOverlay && currentScreenVoiceOver !== prevScreenVoiceOver) {
      setPrevScreenVoiceOver(currentScreenVoiceOver);
      Sound.play(currentScreenVoiceOver, { resume: false });
    }
  }, [currentScreenVoiceOver, showOverlay]); // eslint-disable-line

  const currentScreenFollowingVoiceOver = currentScreen?.followingVoiceOver;
  const prevScreenFollowingVoiceOver = usePreviousValue(currentScreenFollowingVoiceOver);
  useEffect(() => {
    if (!currentScreenFollowingVoiceOver && prevScreenFollowingVoiceOver) {
      setTimeout(() => Sound.play(prevScreenFollowingVoiceOver, { resume: false }), 1000);
    }
  }, [currentScreenFollowingVoiceOver, prevScreenFollowingVoiceOver]);

  // additional sound for mars surface screens
  useEffect(() => {
    if (isMarsScreen) Sound.play(Sound.sfxPlanetAmbience, { resume: true });
    else Sound.pause(Sound.sfxPlanetAmbience);
  }, [isMarsScreen]);

  // for muting/unmuting using UI button
  const toggle = () => {
    if (isProcessing) return;
    setIsProcessing(true);
    setTimeout(() => setIsProcessing(false), 1000);
    setIsMuted2(!isMuted2);
    Sound.setIsMuted(!isMuted2);
  };

  // for muting/unmuting when browser tab gets active/inactive
  useEffect(() => {
    Sound.setIsTabActive(isTabActive);
  }, [isTabActive]); // eslint-disable-line

  return (
    <div
      className={`
        circle-button z-10 top-[32px] pointer-events-auto cursor-pointer active:scale-90 group transition-all
        ${isOnlyIcon ? 'right-[64px]' : 'right-[144px]'}
      `}
      onClick={toggle}
      onMouseDown={() => Sound.playSfxClick()}
      onMouseEnter={() => Sound.playSfxHover()}
    >
      <div className="circle-1" />
      <div className="circle-2" />
      <div className="flex w-full h-full rounded-full flex-row justify-between items-center overflow-hidden px-[14px]">
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <Line key={index} paused={isMuted2 || !isTabActive} index={index} />
          ))}
      </div>
    </div>
  );
}
