import { useEffect, useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { AdditiveBlending, MeshStandardMaterial, SkinnedMesh } from 'three';
import HOLOGRAM_PLANET_MODEL from 'assets/models/hologram.glb';

export function HologramPlanet() {
  const group = useRef(null);
  const { nodes, materials } = useGLTF(HOLOGRAM_PLANET_MODEL);

  useEffect(() => {
    nodes.Ring1_2.userData = {
      animated: true,
      animations: [{ loop: true, autoplay: true, sheetName: 'hologramPlanet', objectName: 'ring1' }]
    };
    nodes.Ring2_3.userData = {
      animated: true,
      animations: [{ loop: true, autoplay: true, sheetName: 'hologramPlanet', objectName: 'ring2' }]
    };
    nodes.Ring3_4.userData = {
      animated: true,
      animations: [{ loop: true, autoplay: true, sheetName: 'hologramPlanet', objectName: 'ring3' }]
    };
    nodes.Ring4_5.userData = {
      animated: true,
      animations: [{ loop: true, autoplay: true, sheetName: 'hologramPlanet', objectName: 'ring4' }]
    };
    nodes.Ring5_6.userData = {
      animated: true,
      animations: [{ loop: true, autoplay: true, sheetName: 'hologramPlanet', objectName: 'ring5' }]
    };
    nodes.Ring6_7.userData = {
      animated: true,
      animations: [{ loop: true, autoplay: true, sheetName: 'hologramPlanet', objectName: 'ring6' }]
    };
    nodes.Planet_0.userData = {
      animated: true,
      animations: [{ loop: true, autoplay: true, sheetName: 'hologramPlanet', objectName: 'earth' }]
    };
    nodes.Clouds_1.userData = {
      animated: true,
      animations: [
        { loop: true, autoplay: true, sheetName: 'hologramPlanet', objectName: 'clouds' }
      ]
    };
  }, [nodes]);

  useEffect(() => {
    (materials.Clouds as MeshStandardMaterial).toneMapped = true;
    (materials.Clouds as MeshStandardMaterial).blending = AdditiveBlending;
    (materials.EarthHologram as MeshStandardMaterial).toneMapped = true;
  }, [materials]);

  return (
    <group
      ref={group}
      userData={{
        animated: true,
        animations: [
          { sheetName: 'default', objectName: 'hologramPlanet' },
          { sheetName: 'spaceSequence', objectName: 'hologramPlanet' }
        ]
      }}>
      <group name="Scene">
        <pointLight
          castShadow={false}
          userData={{
            animated: true,
            animations: [{ sheetName: 'hologramPlanet', objectName: 'pointLight' }]
          }}
          intensity={10}
          distance={0.3}
          color={'#003375'}></pointLight>
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="root">
            <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
              <group name="Armature_10">
                <group name="GLTF_created_0">
                  <primitive object={nodes.GLTF_created_0_rootJoint} />
                  <group name="Planet_9" />
                  <skinnedMesh
                    name="Object_7"
                    geometry={(nodes.Object_7 as SkinnedMesh).geometry}
                    material={materials.EarthHologram}
                    skeleton={(nodes.Object_7 as SkinnedMesh).skeleton}
                  />
                  <skinnedMesh
                    name="Object_8"
                    geometry={(nodes.Object_8 as SkinnedMesh).geometry}
                    material={materials.Clouds}
                    skeleton={(nodes.Object_8 as SkinnedMesh).skeleton}
                  />
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  );
}
