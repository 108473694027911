import * as THREE from 'three';

function encodeInt(array: Uint8Array, index: number, num: number) {
  const int = Math.round(num) >>> 0;

  const r = (int >> 24) & 0xff;
  const g = (int >> 16) & 0xff;
  const b = (int >> 8) & 0xff;
  const a = int & 0xff;

  array[index] = r;
  array[index + 1] = g;
  array[index + 2] = b;
  array[index + 3] = a;
}

export function encodePointsInTexture(points: THREE.Vector3[]) {
  const size = Math.sqrt(2 ** Math.ceil(Math.log2(points.length * 3)));
  const uint8Array = new Uint8Array(size * size * 4);

  points.forEach((point, i) => {
    const xIndex = i * 12;
    const yIndex = xIndex + 4;
    const zIndex = xIndex + 8;

    encodeInt(uint8Array, xIndex, point.x * 10000);
    encodeInt(uint8Array, yIndex, point.y * 10000);
    encodeInt(uint8Array, zIndex, point.z * 10000);
  });

  const dataTexture = new THREE.DataTexture(uint8Array, size, size, THREE.RGBAFormat, THREE.UnsignedByteType);

  dataTexture.wrapS = THREE.RepeatWrapping;
  dataTexture.wrapT = THREE.RepeatWrapping;
  dataTexture.minFilter = THREE.NearestFilter;
  dataTexture.magFilter = THREE.NearestFilter;
  dataTexture.needsUpdate = true;
  dataTexture.offset = new THREE.Vector2(0, 0);

  return { dataTexture, textureSize: size };
}
