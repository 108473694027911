import { memo, useEffect, useMemo } from 'react';
import {
  AdditiveBlending,
  Color,
  FrontSide,
  InstancedBufferAttribute,
  InstancedMesh,
  LineCurve3,
  ShaderMaterial,
  Texture,
  TubeGeometry,
  Uniform
} from 'three';
import fragmentShader from 'shaders/lights.fragment.glsl';
import vertexShader from 'shaders/lights.vertex.glsl';
import distortionShader from 'shaders/distortion.vertex.glsl';
import { useFrame } from '@react-three/fiber';

export interface PathOptions {
  roadLength: number;
  width: number;
  roadWidth: number;
  islandWidth: number;
  nPairs: number;
  roadSections: number;
  distortionTexture: Texture;
  textureSize: number;
  uPointsCount: number;
}

const SECTION_SIZE = 0.1;

function Trails({
  roadOptions,
  color,
  speed,
  offset,
  curve
}: {
  color: Color;
  speed: number;
  roadOptions: PathOptions;
  offset: number;
  curve: LineCurve3;
}) {
  const { roadWidth, roadSections, nPairs, roadLength, distortionTexture, textureSize, uPointsCount } = roadOptions;

  const uniforms = useMemo(
    () => ({
      uColor: new Uniform(color),
      uTotalTravelLength: new Uniform(roadLength),
      uSectionTravelLength: new Uniform(roadLength * SECTION_SIZE),
      uTime: new Uniform(0),
      uOffset: new Uniform(0),
      uSpeed: new Uniform(speed),
      uDistortionTexture: new Uniform(distortionTexture),
      uTextureSize: new Uniform(textureSize),
      uPointsCount: new Uniform(uPointsCount)
    }),
    [] // eslint-disable-line
  );

  const instancedMesh = useMemo(() => {
    const geometry = new TubeGeometry(curve, 100, 1, 8, false);

    const material = new ShaderMaterial({
      // blending: AdditiveBlending,
      transparent: true,
      uniforms: uniforms,
      side: FrontSide,
      toneMapped: true,
      fragmentShader: fragmentShader,
      vertexShader: vertexShader
    });
    material.onBeforeCompile = (shader) => {
      shader.vertexShader = shader.vertexShader.replace('#include <getDistortion_vertex>', distortionShader);
    };
    material.uniforms = uniforms;
    material.uniformsNeedUpdate = true;
    material.needsUpdate = true;

    let aOffset = [];
    let aMetrics = [];

    const sectionWidth = roadWidth / roadSections;

    for (let i = 0; i < nPairs; i++) {
      let radius = Math.random() * 0.1 + 0.1;
      let length = Math.random() * roadLength * 0.05 + roadLength * 0.02 * SECTION_SIZE;
      let section = i % 3;

      let sectionX = section * sectionWidth - roadWidth / 2 + sectionWidth / 2;
      let trailWidth = 0.5 * sectionWidth;
      let offsetX = 0.5 * Math.random() + offset * roadWidth;

      let offsetY = radius * 1.3;

      let offsetZ = Math.random() * roadLength * SECTION_SIZE;

      aOffset.push(sectionX - trailWidth / 2 + offsetX);
      aOffset.push(offsetY);
      aOffset.push(-offsetZ);

      aOffset.push(sectionX + trailWidth / 2 + offsetX);
      aOffset.push(offsetY);
      aOffset.push(-offsetZ);

      aMetrics.push(radius);
      aMetrics.push(length);

      aMetrics.push(radius);
      aMetrics.push(length);
    }
    // Add the offset to the instanced geometry.
    geometry.setAttribute('aOffset', new InstancedBufferAttribute(new Float32Array(aOffset), 3, false));
    geometry.setAttribute('aMetrics', new InstancedBufferAttribute(new Float32Array(aMetrics), 2, false));

    // Update the instance
    const mesh = new InstancedMesh(geometry, material, nPairs * 2);
    return mesh;
  }, []); // eslint-disable-line

  useFrame((state) => {
    if (!instancedMesh) return;
    (instancedMesh.material as ShaderMaterial).uniforms.uTime.value = state.clock.elapsedTime;
  });

  return <primitive object={instancedMesh}></primitive>;
}

export default memo(Trails);
