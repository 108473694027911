import { useThree } from '@react-three/fiber';
import useAppStore from 'hooks/useAppStore';
import { memo, useEffect, useMemo, useState } from 'react';
import { CatmullRomCurve3, Color, Group, InstancedMesh, LineCurve3, ShaderMaterial, Vector3 } from 'three';
import Trails, { PathOptions } from './Trails/Trails';
import { gsap, Linear, Power1 } from 'gsap';
import { moveCameraAlongCurve } from 'utils/CameraUtils';
import { encodePointsInTexture } from 'utils/three.utils';
import { shallow } from 'zustand/shallow';

const POINTS_COUNT = 1000; // IMPORTANT: Also have to assign this in the shader
const ROAD_CURVE = new CatmullRomCurve3(
  [
    new Vector3(-190.49891349554161, 228.50851521977756, -955.4512661187244),
    new Vector3(184.62859816141545, 150.78218561203502, -752.4354752953909),
    new Vector3(-399.5420707369568, 245.02991077147323, -596.1547229914424),
    new Vector3(314.8500682477056, 31.18543625981374, -452.67798815051066),
    new Vector3(-652.7389003578753, 249.21435870690732, -194.94217660282158),
    new Vector3(505.7582862172368, 155.73515728300094, -21.92826265547272),
    new Vector3(-531.2515286707585, 489.1522891949155, 274.46349597865736),
    new Vector3(-383.22614101364906, 294.60435528363445, 672.1284961978652),
    new Vector3(-83.75139509183256, 305.8834972768942, 609.4874141355027),
    new Vector3(-0.48520698205731, 270.0122889313135, 500.45696079101475)
  ],
  false,
  'catmullrom',
  0.66
);
const ROAD_POINTS = ROAD_CURVE.getPoints(POINTS_COUNT);

function RoadToSpaceStation() {
  const options: PathOptions = useMemo(() => {
    const { dataTexture, textureSize } = encodePointsInTexture(ROAD_POINTS);
    return {
      roadLength: 10,
      width: 10,
      roadWidth: 3,
      islandWidth: 2,
      nPairs: 6,
      roadSections: 1,
      distortionTexture: dataTexture,
      textureSize,
      uPointsCount: ROAD_POINTS.length
    };
  }, []);

  const camera = useThree((s) => s.camera);
  const [currentStep, hasEnteredAfterLoad, setShowOverlay, direction] = useAppStore(
    (s) => [s.currentStep, s.hasEnteredAfterLoad, s.setShowOverlay, s.direction],
    shallow
  );
  const line = new LineCurve3(new Vector3(0, 0, 0), new Vector3(0, 0, -1));

  const [group, setGroup] = useState<Group | null>();

  const stepTimelines: { [step: number]: [number, number] } = {
    3: [0.0, 0.33],
    4: [0.33, 0.66],
    5: [0.66, 1]
  };
  const active = useMemo(() => {
    return !(
      (direction === 'normal' && !stepTimelines[currentStep]) ||
      (direction === 'reverse' && !stepTimelines[currentStep + 1]) ||
      !hasEnteredAfterLoad ||
      !group
    );
  }, [currentStep, hasEnteredAfterLoad, group]); // eslint-disable-line

  useEffect(() => {
    if (!active) return;
    if (direction === 'normal') {
      const animation = cameraAnimation(stepTimelines[currentStep][0], stepTimelines[currentStep][1]);
      return () => {
        animation?.kill();
      };
    } else {
      const animation = cameraAnimation(stepTimelines[currentStep + 1][1], stepTimelines[currentStep + 1][0]);
      return () => {
        animation?.kill();
      };
    }
  }, [active, currentStep]); // eslint-disable-line

  const updateTrailOffset = (time: number) => {
    if (!group) return;
    ((group.children[0] as InstancedMesh).material as ShaderMaterial).uniforms.uOffset.value = time;
    ((group.children[1] as InstancedMesh).material as ShaderMaterial).uniforms.uOffset.value = time;
  };

  const cameraAnimation = (start: number, end: number) => {
    if (!group) return;
    const animation = { value: 0.0 };
    const duration = 8.0;
    const timeline = gsap.timeline({
      onComplete: () => {
        setShowOverlay(true);
        timeline.kill();
      },
      onStart: () => {
        setShowOverlay(false);
      }
    });

    timeline.fromTo(
      animation,
      { value: 0.0 },
      {
        ease: Power1.easeIn,
        duration: duration * 0.25,
        value: 0.2,
        onUpdate: () => {
          const { value } = animation;
          const time = start + value * (end - start);
          updateTrailOffset(time);
          moveCameraAlongCurve(camera, group!, ROAD_POINTS, -0.1, time);
        },
        overwrite: true
      }
    );
    timeline.fromTo(
      animation,
      { value: 0.2 },
      {
        ease: Linear.easeNone,
        duration: duration * 0.5,
        value: 0.8,
        onUpdate: () => {
          const { value } = animation;
          const time = start + value * (end - start);
          updateTrailOffset(time);
          moveCameraAlongCurve(camera, group!, ROAD_POINTS, -0.1, time);
        },
        delay: currentStep === 9 ? 6.0 : 0,
        overwrite: false,
        immediateRender: false
      }
    );
    timeline.fromTo(
      animation,
      { value: 0.8 },
      {
        ease: Power1.easeOut,
        duration: duration * 0.4,
        value: 1.0,
        onUpdate: () => {
          const { value } = animation;
          const time = start + value * (end - start);
          updateTrailOffset(time);
          moveCameraAlongCurve(camera, group!, ROAD_POINTS, -0.1, time);
        },
        overwrite: false,
        immediateRender: false
      }
    );

    return timeline;
  };

  return (
    <group
      visible={active || currentStep === 2}
      userData={{
        animated: true,
        animations: [{ objectName: 'trails', sheetName: 'spaceSequence' }]
      }}
      ref={setGroup}
    >
      <Trails roadOptions={options} color={new Color('#6AFFE4')} offset={1.5} speed={0.5} curve={line}></Trails>
      <Trails roadOptions={options} color={new Color('#FF8D24')} offset={-1.5} speed={0.5} curve={line}></Trails>
    </group>
  );
}

export default memo(RoadToSpaceStation);
