import { useTexture } from '@react-three/drei';
import { useState } from 'react';
import { Color, Mesh, Vector2 } from 'three';
import { gsap } from 'gsap';

export function TiltingPicture({ pictureUrl, id }: { pictureUrl: string; id: string }) {
  const texture = useTexture(pictureUrl);
  const [meshRef, setMeshRef] = useState<Mesh | null>(null);

  const tiltOnPointerMove = (event: any) => {
    const interesection = event.intersections[0];

    if (interesection && meshRef) {
      const rotation: Vector2 = (interesection.uv as Vector2)
        .subScalar(0.5)
        .multiplyScalar(Math.PI)
        .divideScalar(5);
        
      gsap.to(meshRef.rotation, {
        x: -rotation.y,
        y: rotation.x,
        duration: 0.1
      });
      document.body.style.cursor = 'pointer';
    }
  };

  return (
    <group
      userData={{
        animated: true,
        animations: [
          {
            sheetName: 'marsSequence',
            objectName: id
          }
        ]
      }}>
      <mesh
        ref={setMeshRef}
        onPointerMove={tiltOnPointerMove}
        onPointerLeave={() => {
          document.body.style.cursor = 'auto';
        }}
        rotation={[0, 0, 0]}
        position={[0, 0, 0]}
        scale={[1, 1, 1]}>
        <planeGeometry args={[1, 1, 2, 2]}></planeGeometry>
        <meshBasicMaterial map={texture} color={new Color('#d5d5d5')} transparent></meshBasicMaterial>
      </mesh>
    </group>
  );
}
