import {
  Environment,
  PerspectiveCamera,
  Html,
  useTexture,
  useVideoTexture,
  PerformanceMonitor,
  PerformanceMonitorApi
} from '@react-three/drei';
import { memo, useEffect, useMemo } from 'react';
import useTheatherJS from 'hooks/useTheaterJS';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import SpacestationScene from 'components/SpacestationScene/SpacestationScene';
import { useFrame, useThree } from '@react-three/fiber';
import IntroScene from 'components/IntroScene/IntroScene';
import { BackSide, Vector2 } from 'three';
import ScreenContent from 'components/ScreenContent/ScreenContent';
import useAppStore from 'hooks/useAppStore';
import config from 'assets/theater/homepage.json';
import MarsScene from 'components/MarsScene/MarsScene';
import SKYBOX_TEXTURE from 'assets/textures/skybox-space.jpg';
import ENVMAP from 'assets/textures/envmap.hdr';
import NIGHT_ENVMAP from 'assets/textures/night_envmap.hdr';
import EarthScene from 'components/EarthScene/EarthScene';
import { isMobileOrTablet } from 'utils/utils';
import EARTH_VIDEO_URL from 'assets/videos/earth.mp4';

const STEPS_WITH_EARTH_VISIBLE = [0, 1, 20, 22, 23, 24];

const BLOOM_PARAMS = {
  strength: 20.0,
  threshold: 0.2,
  radius: 0.4
};

const HIGH_QUALITY_THRESHOLD = 0.4;
const MEDIUM_QUALITY_THRESHOLD = 0.3;

function Renderer() {
  const scene = useThree((state) => state.scene);
  const gl = useThree((s) => s.gl);
  const camera = useThree((s) => s.camera);
  const size = useThree((s) => s.size);

  const currentStep = useAppStore((s) => s.currentStep);
  const hasLoaded = useAppStore((s) => s.hasLoaded);
  const showOverlay = useAppStore((s) => s.showOverlay);
  const hasEnteredAfterLoad = useAppStore((s) => s.hasEnteredAfterLoad);
  const setQuality = useAppStore((s) => s.setQuality);
  const { addSceneAnimations } = useTheatherJS('Homepage', { state: config });

  const effectComposer = useMemo(() => {
    const composer = new EffectComposer(gl);
    composer.renderToScreen = true;
    composer.addPass(new RenderPass(scene, camera));
    const bloomPass = new UnrealBloomPass(
      new Vector2(size.width, size.height),
      BLOOM_PARAMS.strength,
      BLOOM_PARAMS.radius,
      BLOOM_PARAMS.threshold
    );
    bloomPass.nMips = 0;
    composer.addPass(bloomPass);
    return composer;
  }, []);

  useEffect(() => {
    (effectComposer.passes[0] as RenderPass).scene = scene;
    (effectComposer.passes[0] as RenderPass).camera = camera;
  }, [scene, camera]);

  useEffect(() => {
    effectComposer.setSize(size.width, size.height);
    (effectComposer.passes[0] as RenderPass).setSize(size.width, size.height);
    (effectComposer.passes[1] as UnrealBloomPass).setSize(size.width, size.height);
  }, [size]);

  useEffect(() => {
    effectComposer.passes[1].enabled = currentStep < 13;
  }, [currentStep]);

  useEffect(() => {
    if (hasLoaded && scene) {
      addSceneAnimations(scene);
    }
  }, [hasLoaded]); // eslint-disable-line

  const skybox = useTexture(SKYBOX_TEXTURE);
  const earthVideoTexture = useVideoTexture(EARTH_VIDEO_URL, {
    unsuspend: 'loadedmetadata',
    loop: true,
    autoplay: false,
    playsInline: true,
    muted: true
  });

  useEffect(() => {
    const video = earthVideoTexture.image as HTMLVideoElement;
    if (!video) return;

    if (STEPS_WITH_EARTH_VISIBLE.includes(currentStep)) video.play();
    else if (currentStep === 21)
      setTimeout(() => {
        video.pause();
      }, 3000);
    else video.pause();
  }, [currentStep, earthVideoTexture]);

  useFrame(() => {
    if ((currentStep === 2 || currentStep === 21) && showOverlay) return; // don't render the 3D background if we are playing a full-scren video
    effectComposer.render();
  }, 1);

  function onPerformanceChange({ factor }: PerformanceMonitorApi) {
    effectComposer.setPixelRatio(0.5 + 1.5 * factor);
    if (factor > HIGH_QUALITY_THRESHOLD) setQuality(2);
    else if (factor > MEDIUM_QUALITY_THRESHOLD) setQuality(1);
    else setQuality(0);
  }

  return (
    <>
      <PerformanceMonitor onChange={onPerformanceChange} />
      {currentStep < 13 || currentStep > 19 ? <Environment files={NIGHT_ENVMAP} /> : <Environment files={ENVMAP} />}
      <group
        userData={{
          animated: true,
          animations: [
            { objectName: 'camera', sheetName: 'earthSequence' },
            { objectName: 'camera', sheetName: 'marsSequence' },
            { objectName: 'camera', sheetName: 'spaceSequence' },
            { objectName: 'camera', sheetName: 'intro' }
          ]
        }}
      >
        <PerspectiveCamera makeDefault position={[0, 0, 0]} far={2000} fov={50} near={0.1} />
        <Html
          zIndexRange={[0, 1]}
          transform
          center
          position={[0, 0, -27]}
          pointerEvents={currentStep === 15? 'none' : 'auto'}
        >
          <ScreenContent />
        </Html>
      </group>
      <mesh visible={!(currentStep > 9 && currentStep < 20)} scale={[500, 500, 500]}>
        <sphereGeometry args={[1, 20, 20]} />
        <meshBasicMaterial
          map={skybox}
          userData={{
            animated: true,
            animations: [
              { objectName: 'spaceSkyboxMaterial', sheetName: 'earthSequence' },
              { objectName: 'spaceSkyboxMaterial', sheetName: 'spaceSequence' }
            ]
          }}
          transparent={false}
          side={BackSide}
        />
      </mesh>
      <IntroScene visible={currentStep <= 1 || !hasEnteredAfterLoad} videoTexture={earthVideoTexture} />
      {!isMobileOrTablet() && (
        <>
          <SpacestationScene visible={(currentStep >= 2 && currentStep <= 12) || !hasEnteredAfterLoad} />
          <MarsScene visible={(currentStep > 12 && currentStep < 20) || !hasEnteredAfterLoad} />
          <EarthScene visible={currentStep > 19 || !hasEnteredAfterLoad} videoTexture={earthVideoTexture} />
        </>
      )}
    </>
  );
}

export default memo(Renderer);
