import { useEffect, useRef, useState } from 'react';
import { gsap, Power2 } from 'gsap';
import { screens } from 'components/ScreenContent/ScreenContent';
import useAppStore from 'hooks/useAppStore';
import Sound from 'utils/sound';

function Line({ index, isOpened }: { index: number; isOpened: boolean }) {
  const ref = useRef<HTMLDivElement>(null);

  const [animation, setAnimation] = useState<gsap.core.Tween>();

  useEffect(() => {
    const gsapAnimation = gsap.to(ref.current, {
      duration: '0.4',
      transform: index === 1 ? 'rotate(45deg)' : 'rotate(-45deg)',
      top: '32px',
      ease: Power2.easeOut,
      paused: true
    });
    setAnimation(gsapAnimation);

    return () => {
      animation?.kill();
      setAnimation(undefined);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    !!isOpened ? animation?.play() : animation?.reverse();
  }, [isOpened]); // eslint-disable-line

  return (
    <div
      id={`audio-line-${index}`}
      className={`
        absolute left-[18px] w-[33px] h-[3px] border-[1px] rounded-full
        border-[white] group-hover:border-[orange] bg-[white] group-hover:bg-[orange]
        ${index === 0 ? 'top-[26px]' : 'top-[38px]'}
      `}
      ref={ref}
    />
  );
}

export default function MenuButton() {
  const [isOpened, setIsOpened] = useState(false);
  const setCurrentStep = useAppStore((s) => s.setCurrentStep);
  const setShowOverlay = useAppStore((s) => s.setShowOverlay);

  const toggle = () => {
    setIsOpened(!isOpened);
  };

  return (
    <div className="w-full h-full absolute top-0 left-0 pointer-events-none z-50">
      <div
        className={`
          fixed w-full h-full z-10 backdrop-blur-lg transition-all duration-400 flex justify-center items-center
          ${isOpened ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
        `}
        style={{ backgroundColor: 'rgb(0, 0, 0, 0.85)' }}
      >
        <div className="relative h-full p-[200px] justify-center text-center overflow-scroll scrollbar-hide">
          {screens
            .filter((s) => s.isMenuItem)
            .map((screen: any, index: number) => (
              <div
                className="text-h2 text-white mb-[24px] hover:text-orange hover-gradiend-underline transition-all duration-400 cursor-pointer active:scale-90"
                key={index}
                onClick={() => {
                  if (screen.step === 14) {
                    setCurrentStep(13);
                    setShowOverlay(false);
                  } // Step 13-14 is a transition
                  else setCurrentStep(screen.step);
                  toggle();
                }}
                onMouseDown={() => Sound.playSfxClick()}
                onMouseEnter={() => Sound.playSfxHover()}
              >
                {screen.title}
              </div>
            ))}
        </div>
      </div>
      <div
        className="circle-button z-10 right-[64px] top-[32px] pointer-events-auto cursor-pointer active:scale-90 group transition-all"
        onClick={toggle}
        onMouseDown={() => Sound.playSfxClick()}
        onMouseEnter={() => Sound.playSfxHover()}
      >
        <div className="circle-1" />
        <div className="circle-2" />
        <Line isOpened={isOpened} index={0} />
        <Line isOpened={isOpened} index={1} />
      </div>
    </div>
  );
}
