import { IProject, IProjectConfig, getProject } from '@theatre/core';
import studio from '@theatre/studio';
import { AnimationStore } from 'interfaces/interfaces';
import { createContext, useCallback, useState } from 'react';

if (process.env.NODE_ENV === 'development') {
  studio.initialize();
}

export const AnimationContext = createContext<AnimationStore>({
  project: undefined,
  setProjectConfig: () => {}
});

export function useTheaterContext() {
  const [project, setProject] = useState<IProject>();

  const setProjectConfig = useCallback(
    (id: string, configFile: IProjectConfig) => {
      if (!project || project.address.projectId !== id) {
        const newProject = getProject(id, configFile);
        setProject(newProject);
      }
    },
    [project]
  );

  return { project, setProjectConfig };
}
