import OST_0 from 'assets/audio/OST_0.mp3';
import OST_1 from 'assets/audio/OST_1.mp3';
import OST_2 from 'assets/audio/OST_2.mp3';
import OST_3 from 'assets/audio/OST_3.mp3';
import OST_4 from 'assets/audio/OST_4.mp3';
import OST_5 from 'assets/audio/OST_5.mp3';
import OST_SLOVENIA from 'assets/audio/OST_slovenia.mp3';
import OST_LAST_SCREEN from 'assets/audio/OST_last_screen.mp3';
import SFX_CLICK from 'assets/audio/SFX_mouse_click.mp3';
import SFX_HOVER from 'assets/audio/SFX_mouse_hover.mp3';
import SFX_HYPERSPEED from 'assets/audio/SFX_hyperspeed.mp3';
import SFX_PLANET_AMBIENCE from 'assets/audio/SFX_planet_ambience.mp3';
import SCREEN_STARSHIP_VOICE_OVER from 'assets/audio/screen-starship-voice-over.mp3';
import SCREEN_KEYBOARD_INFO_VOICE_OVER from 'assets/audio/screen-keyboard-info-voice-over.mp3';
import SCREEN_CODE_VOICE_OVER from 'assets/audio/screen-code-voice-over.mp3';
import SCREEN_4_VOICE_OVER from 'assets/audio/screen-4-voice-over.mp3';
import SCREEN_4_FOLLOWING_VOICE_OVER from 'assets/audio/screen-4-following-voice-over.mp3';
import SCREEN_6_VOICE_OVER from 'assets/audio/screen-6-voice-over.mp3';
import SCREEN_8_VOICE_OVER from 'assets/audio/screen-8-voice-over.mp3';
import SCREEN_9_VOICE_OVER from 'assets/audio/screen-9-voice-over.mp3';
import SCREEN_13_VOICE_OVER from 'assets/audio/screen-13-voice-over.mp3';
import SCREEN_14_VOICE_OVER from 'assets/audio/screen-14-voice-over.mp3';
import SCREEN_21_VOICE_OVER from 'assets/audio/screen-21-voice-over.mp3';
import SCREEN_25_VOICE_OVER from 'assets/audio/screen-25-voice-over.mp3';
import SCREEN_26_VOICE_OVER from 'assets/audio/screen-26-voice-over.mp3';
import SCREEN_26B_VOICE_OVER from 'assets/audio/screen-26b-voice-over.mp3';
import SCREEN_27_VOICE_OVER from 'assets/audio/screen-27-voice-over.mp3';
import SCREEN_29_VOICE_OVER from 'assets/audio/screen-29-voice-over.mp3';
import SCREEN_42_VOICE_OVER from 'assets/audio/screen-42-voice-over.mp3';
import SCREEN_44_VOICE_OVER from 'assets/audio/screen-44-voice-over.mp3';
import SCREEN_76_VOICE_OVER from 'assets/audio/screen-76-voice-over.mp3';
import SCREEN_77_VOICE_OVER from 'assets/audio/screen-77-voice-over.mp3';
import SCREEN_78_VOICE_OVER from 'assets/audio/screen-78-voice-over.mp3';
import SCREEN_82_VOICE_OVER from 'assets/audio/screen-82-voice-over.mp3';
import SCREEN_83_VOICE_OVER from 'assets/audio/screen-83-voice-over.mp3';
import SCREEN_999_VOICE_OVER from 'assets/audio/screen-999-voice-over.mp3';
import { Howl } from 'howler';

const ost0 = new Howl({ src: [OST_0], loop: true, preload: false });
const ost1 = new Howl({ src: [OST_1], preload: false });
const ost2 = new Howl({ src: [OST_2], preload: false });
const ost3 = new Howl({ src: [OST_3], preload: false });
const ost4 = new Howl({ src: [OST_4], preload: false });
const ost5 = new Howl({ src: [OST_5], preload: false });
const ostSlovenia = new Howl({ src: [OST_SLOVENIA], preload: false });
const ostLastScreen = new Howl({ src: [OST_LAST_SCREEN], preload: false });
const sfxClick = new Howl({ src: [SFX_CLICK], preload: false });
const sfxHover = new Howl({ src: [SFX_HOVER], preload: false });
const sfxHyperSpeed = new Howl({ src: [SFX_HYPERSPEED], preload: false });
const sfxPlanetAmbience = new Howl({ src: [SFX_PLANET_AMBIENCE], loop: true, preload: false });

const screenStarshipVoiceOver = new Howl({ src: [SCREEN_STARSHIP_VOICE_OVER], preload: false });
const screenCodeVoiceOver = new Howl({ src: [SCREEN_CODE_VOICE_OVER], preload: false });
const screenKeyboardInfoVoiceOver = new Howl({ src: [SCREEN_KEYBOARD_INFO_VOICE_OVER], preload: false });
const screen4VoiceOver = new Howl({ src: [SCREEN_4_VOICE_OVER], preload: false });
const screen4FollowingVoiceOver = new Howl({ src: [SCREEN_4_FOLLOWING_VOICE_OVER], preload: false });
const screen6VoiceOver = new Howl({ src: [SCREEN_6_VOICE_OVER], preload: false });
const screen8VoiceOver = new Howl({ src: [SCREEN_8_VOICE_OVER], preload: false });
const screen9VoiceOver = new Howl({ src: [SCREEN_9_VOICE_OVER], preload: false });
const screen13VoiceOver = new Howl({ src: [SCREEN_13_VOICE_OVER], preload: false });
const screen14VoiceOver = new Howl({ src: [SCREEN_14_VOICE_OVER], preload: false });
const screen21VoiceOver = new Howl({ src: [SCREEN_21_VOICE_OVER], preload: false });
const screen25VoiceOver = new Howl({ src: [SCREEN_25_VOICE_OVER], preload: false });
const screen26VoiceOver = new Howl({ src: [SCREEN_26_VOICE_OVER], preload: false });
const screen26BVoiceOver = new Howl({ src: [SCREEN_26B_VOICE_OVER], preload: false });
const screen27VoiceOver = new Howl({ src: [SCREEN_27_VOICE_OVER], preload: false });
const screen29VoiceOver = new Howl({ src: [SCREEN_29_VOICE_OVER], preload: false });
const screen42VoiceOver = new Howl({ src: [SCREEN_42_VOICE_OVER], preload: false });
const screen44VoiceOver = new Howl({ src: [SCREEN_44_VOICE_OVER], preload: false });
const screen76VoiceOver = new Howl({ src: [SCREEN_76_VOICE_OVER], preload: false });
const screen77VoiceOver = new Howl({ src: [SCREEN_77_VOICE_OVER], preload: false });
const screen78VoiceOver = new Howl({ src: [SCREEN_78_VOICE_OVER], preload: false });
const screen82VoiceOver = new Howl({ src: [SCREEN_82_VOICE_OVER], preload: false });
const screen83VoiceOver = new Howl({ src: [SCREEN_83_VOICE_OVER], preload: false });
const screen999VoiceOver = new Howl({ src: [SCREEN_999_VOICE_OVER], preload: false });

const LOCAL_STORAGE_KEY_MUTE = '_MUTE';

export const voiceOverAudios = {
  screenStarshipVoiceOver,
  screenCodeVoiceOver,
  screenKeyboardInfoVoiceOver,
  screen4VoiceOver,
  screen6VoiceOver,
  screen8VoiceOver,
  screen9VoiceOver,
  screen13VoiceOver,
  screen14VoiceOver,
  screen21VoiceOver,
  screen25VoiceOver,
  screen26VoiceOver,
  screen26BVoiceOver,
  screen27VoiceOver,
  screen29VoiceOver,
  screen42VoiceOver,
  screen44VoiceOver,
  screen76VoiceOver,
  screen77VoiceOver,
  screen78VoiceOver,
  screen82VoiceOver,
  screen83VoiceOver,
  screen999VoiceOver
};

const Sounds = {
  ost0,
  ost1,
  ost2,
  ost3,
  ost4,
  ost5,
  ostSlovenia,
  ostLastScreen,
  screen4FollowingVoiceOver,
  sfxClick,
  sfxHover,
  sfxHyperSpeed,
  sfxPlanetAmbience,
  ...voiceOverAudios
};

let isMuted = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_MUTE) || 'false') as boolean;

function getIsMuted() {
  return isMuted;
}

interface PlayConfig {
  resume?: boolean;
  fade?: number;
}

function play(audio: Howl | undefined, config: PlayConfig = {}) {
  if (!audio || audio.playing()) return;
  if (audio.state() === 'unloaded') audio.load();
  const { resume = false } = config;
  if (!resume) audio.seek(0);

  audio.play();
}

function pause(audio: Howl) {
  audio.pause();
}

const ostAudios = [ost0, ost1, ost2, ost3, ost4, ost5];
const ostAll = [ostSlovenia, ostLastScreen, ...ostAudios];
let activeAudioIndex = 1;

function playOST(index?: number) {
  if (index === 0) {
    ostAll.forEach((audio) => audio !== ost0 && audio.playing() && audio.pause());
    play(ost0, { resume: true });
  } else {
    const activeAudio = ostAudios[activeAudioIndex];
    ostAll.forEach((audio) => audio !== activeAudio && audio.playing() && audio.pause());
    play(activeAudio, { resume: true });

    activeAudio.once('end', () => {
      activeAudioIndex = ((activeAudioIndex + 1) % 5) + 1;
      playOST();
    });
  }
}

function playOSTSlovenia() {
  ostAll.forEach((audio) => audio !== ostSlovenia && audio.playing() && audio.pause());
  play(ostSlovenia, { resume: true });
  ostSlovenia.once('end', () => playOST());
}

function playOSTLastScreen() {
  ostAll.forEach((audio) => audio !== ostLastScreen && audio.playing() && audio.pause());
  play(ostLastScreen, { resume: true });
  ostLastScreen.once('end', () => playOST());
}

function pauseOST() {
  ostAudios.forEach((audio) => audio.playing() && audio.pause());
}

function pauseAllVoiceOverAudios() {
  Object.values(voiceOverAudios).forEach((audio) => audio.playing() && audio.pause());
}

function setIsMuted(value: boolean) {
  isMuted = value;
  localStorage.setItem(LOCAL_STORAGE_KEY_MUTE, String(value));
  Object.values(Sounds).forEach((audio) => audio.mute(value));
}

function setIsTabActive(value: boolean) {
  Object.values(Sounds).forEach((audio) => audio.mute(isMuted || !value));
}

const Sound = {
  play,
  pause,
  playOST,
  playOSTSlovenia,
  playOSTLastScreen,
  pauseOST,
  pauseAllVoiceOverAudios,
  playSfxClick: () => play(sfxClick),
  playSfxHover: () => play(sfxHover),
  playSfxHyperSpeed: () => play(sfxHyperSpeed),
  setIsMuted,
  getIsMuted,
  setIsTabActive,
  ...Sounds
};

export default Sound;
