import { useThree } from '@react-three/fiber';
import useAppStore from 'hooks/useAppStore';
import { memo, useEffect, useMemo, useState } from 'react';
import { CatmullRomCurve3, Color, Group, InstancedMesh, LineCurve3, ShaderMaterial, Vector3 } from 'three';
import Trails, { PathOptions } from './Trails/Trails';
import { gsap, Linear, Power1, Power2 } from 'gsap';
import { moveCameraAlongCurve } from 'utils/CameraUtils';
import { encodePointsInTexture } from 'utils/three.utils';
import { shallow } from 'zustand/shallow';

const POINTS_COUNT = 1000; // IMPORTANT: Also have to assign this in the shader
const ROAD_CURVE = new CatmullRomCurve3(
  [
    new Vector3(-50.59037427024518, 135.4067911091623, 1000.6555515467988),
    new Vector3(-50.59037427024518, 135.4067911091623, 646.6555515467988),
    new Vector3(-240.59037427024518, 135.4067911091623, 646.6555515467988),
    new Vector3(-297.6144796185066, 91.00602698166347, 306.7763798491235),
    new Vector3(-305.5874833078797, -31.562293203178996, -58.75201085100599),
    new Vector3(-557.7614528712575, 271.0894039757157, -330.31117725342693),
    new Vector3(-31.41545600395981, 519.929504450509, 243.44832036113138),
    new Vector3(475.46715716835854, 239.4115099088093, 5.956073413607697),
    new Vector3(405.10313769816537, 231.77755304066363, -519.8631862708244),
    new Vector3(-32.889469332397084, 135.4067911091623, -691.4520859842593),
    new Vector3(-9.589376466459797, 55.46523794200238, -421.70269401712017)
  ],
  false,
  'catmullrom',
  0.66
);
const ROAD_POINTS = ROAD_CURVE.getPoints(POINTS_COUNT);

function RoadAroundMars() {
  const options: PathOptions = useMemo(() => {
    const { dataTexture, textureSize } = encodePointsInTexture(ROAD_POINTS);
    return {
      roadLength: 10,
      width: 10,
      roadWidth: 3,
      islandWidth: 2,
      nPairs: 6,
      roadSections: 1,
      distortionTexture: dataTexture,
      textureSize,
      uPointsCount: ROAD_POINTS.length
    };
  }, []);

  const camera = useThree((s) => s.camera);
  const [currentStep, hasEnteredAfterLoad, setShowOverlay, direction, nextStep, previousStep] = useAppStore(
    (s) => [s.currentStep, s.hasEnteredAfterLoad, s.setShowOverlay, s.direction, s.nextStep, s.previousStep],
    shallow
  );
  const line = new LineCurve3(new Vector3(0, 0, 0), new Vector3(0, 0, -1));

  const [group, setGroup] = useState<Group | null>();

  const stepTimelines: { [step: number]: [number, number] } = {
    9: [0.0, 0.25],
    10: [0.25, 0.5],
    11: [0.5, 0.75],
    12: [0.75, 1.0]
  };
  const visible = useMemo(() => {
    return (
      !(
        (direction === 'normal' && !stepTimelines[currentStep]) ||
        (direction === 'reverse' && !stepTimelines[currentStep + 1]) ||
        !hasEnteredAfterLoad ||
        !group
      ) ||
      (currentStep === 12 && direction === 'reverse')
    );
  }, [currentStep, hasEnteredAfterLoad, group]); // eslint-disable-line

  useEffect(() => {
    if (!visible || (currentStep === 11 && direction === 'reverse')) return;
    if (currentStep === 12) {
      if (direction === 'normal') {
        const animation = cameraAnimation(stepTimelines[currentStep][0], stepTimelines[currentStep][1]);
        const transition = gsap.to('#transition', {
          opacity: 1.0,
          duration: 1.5,
          delay: 2.5,
          ease: Power2.easeInOut
        });
        return () => {
          animation.kill();
          transition.kill();
        };
      } else {
        const animation = cameraAnimation(stepTimelines[currentStep][1], stepTimelines[currentStep][0]);
        gsap.to('#transition', {
          opacity: 0.0,
          duration: 1.0,
          ease: Power2.easeInOut
        });
        return () => {
          animation.kill();
        };
      }
    }

    if (direction === 'normal') {
      const animation = cameraAnimation(stepTimelines[currentStep][0], stepTimelines[currentStep][1]);
      return () => animation.kill();
    } else {
      const animation = cameraAnimation(stepTimelines[currentStep + 1][1], stepTimelines[currentStep + 1][0]);
      return () => animation.kill();
    }
  }, [visible, currentStep]); // eslint-disable-line

  const updateTrailOffset = (time: number) => {
    if (!group) return;
    ((group.children[0] as InstancedMesh).material as ShaderMaterial).uniforms.uOffset.value = time;
    ((group.children[1] as InstancedMesh).material as ShaderMaterial).uniforms.uOffset.value = time;
  };

  const cameraAnimation = (start: number, end: number) => {
    const animation = { value: 0.0 };
    const duration = 4;
    const timeline = gsap.timeline({
      onComplete: () => {
        switch (currentStep) {
          case 12:
            if (direction === 'normal') {
              nextStep();
            } else if (direction === 'reverse') {
              previousStep();
              setShowOverlay(true);
            }
            break;
          default:
            if (!(currentStep === 8 && direction === 'reverse')) setShowOverlay(true);
        }
        timeline.kill();
      },
      onStart: () => {
        setShowOverlay(false);
      }
    });

    timeline.fromTo(
      animation,
      { value: 0.0 },
      {
        ease: Power1.easeIn,
        duration: duration * 0.25,
        value: 0.2,
        onUpdate: () => {
          const { value } = animation;
          const time = start + value * (end - start);
          updateTrailOffset(time);
          moveCameraAlongCurve(camera, group!, ROAD_POINTS, 0.1, time);
        },
        delay: currentStep === 9 && direction === 'normal' ? 6.0 : 0,
        overwrite: true
      }
    );
    timeline.fromTo(
      animation,
      { value: 0.2 },
      {
        ease: Linear.easeNone,
        duration: duration * 0.5,
        value: 0.8,
        onUpdate: () => {
          const { value } = animation;
          const time = start + value * (end - start);
          updateTrailOffset(time);
          moveCameraAlongCurve(camera, group!, ROAD_POINTS, 0.1, time);
        },
        overwrite: false,
        immediateRender: false
      }
    );
    timeline.fromTo(
      animation,
      { value: 0.8 },
      {
        ease: Power1.easeOut,
        duration: duration * 0.4,
        value: 1.0,
        onUpdate: () => {
          const { value } = animation;
          const time = start + value * (end - start);
          updateTrailOffset(time);
          moveCameraAlongCurve(camera, group!, ROAD_POINTS, 0.1, time);
        },
        overwrite: false,
        immediateRender: false
      }
    );
    timeline.play();
    return timeline;
  };

  return (
    <group
      visible={visible}
      userData={{
        animated: true,
        animations: [{ objectName: 'trailsMars', sheetName: 'spaceSequence' }]
      }}
      ref={setGroup}
    >
      <Trails roadOptions={options} color={new Color('#6AFFE4')} offset={1.5} speed={0.5} curve={line} />
      <Trails roadOptions={options} color={new Color('#FF8D24')} offset={-1.5} speed={0.5} curve={line} />
    </group>
  );
}

export default memo(RoadAroundMars);
