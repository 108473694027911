import SCREEN_83_ALL from 'assets/images/screen-83-all.png';
import { useState } from 'react';
import useAppStore from 'hooks/useAppStore';
import { shallow } from 'zustand/shallow';

const APIKey = 'zzRwcm1A8FNrUaPvTix6Z3H7UOITBIggD2FDU2PFAhE0wYX97rRSjkc7RbmH';

export default function PeachAIScreen() {
  const [setBackground, loadingBackground] = useAppStore((s) => [s.setBackground, s.loadingBackground], shallow);
  const [percent, setPercent] = useState(0);
  const [blockadePrompt, setBlockadePrompt] = useState('');
  const [blockadeStatus, setBlockadeStatus] = useState('complete');

  const createBlockade = async () => {
    setBlockadeStatus('started');

    const formData = new FormData();
    formData.append('api_key', APIKey);
    formData.append('prompt', blockadePrompt || 'beautiful desert planet');
    formData.append('skybox_style_id', '5'); // digital painting
    const res = await fetch(`https://backend.blockadelabs.com/api/v1/skybox`, {
      method: 'POST',
      body: formData
    }).then((res) => res.json());

    setBlockadeStatus(res.status);

    setTimeout(() => getBlockadeData(res.id), 5000);
  };

  const getBlockadeData = async (id: number) => {
    setPercent((percent) => percent + Math.round(7 * Math.random()));

    const res = await fetch(`https://backend.blockadelabs.com/api/v1/imagine/requests/${id}?api_key=${APIKey}`).then(
      (res) => res.json()
    );

    setBlockadeStatus(res.request.status);
    if (res.request.status === 'complete') {
      setPercent(0);
      setBlockadePrompt('');
      setBackground(res.request.file_url);
    } else {
      setTimeout(() => getBlockadeData(id), 5000);
    }
  };

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <img alt="all" className="w-[135%] mt-[-30%] max-w-[2090px]" src={SCREEN_83_ALL} />
      <input
        className="
          w-[963px] h-[110px] mt-[-21%] bg-[#151517] border-2 border-[#ED7AE6] px-[45px] rounded-2xl text-h2 text-[#ED7AE6]
        "
        disabled={blockadeStatus !== 'complete'}
        onChange={(e) => setBlockadePrompt(e.target.value)}
        placeholder="E.g. beautiful desert planet"
        value={blockadePrompt}
      />
      <div
        className="
          mt-[4%] whitespace-nowrap text-white text-h2 border-grey border-[2px] px-[72px] py-[21px] rounded-full
          cursor-pointer bg-grey backdrop-blur-lg
        "
        onClick={blockadeStatus === 'complete' ? createBlockade : undefined}
      >
        {blockadeStatus === 'complete'
          ? loadingBackground
            ? 'LOADING... '
            : 'GENERATE AI ENVIRONMENT'
          : `GENERATING... ${percent > 99 ? 99 : percent}%`}
      </div>
    </div>
  );
}
