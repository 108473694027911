import { onChange, val } from '@theatre/core';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { AnimationContext } from './useTheaterContext';

interface PlayOptions {
  direction?: 'normal' | 'reverse';
  range?: [number, number];
  delay?: number;
}

interface AnimationOptions {
  onComplete?: (direction?: 'normal' | 'reverse') => void;
  triggers?: { time: number; timeReverse: number; function: () => void }[];
}

export default function useTheaterAnimation(sheetName: string, options: AnimationOptions = {}) {
  const { project } = useContext(AnimationContext);

  const { onComplete, triggers } = options;

  const sheet = useMemo(() => project?.sheet(sheetName), [project, sheetName]);

  const playOptions = useRef<PlayOptions>({});

  const isPlaying = useRef(false);

  const hasCalledTrigger = useRef<{ [idx: number]: boolean }>({});

  const play = (options: PlayOptions = {}) => {
    if (!sheet) return;
    hasCalledTrigger.current = {};
    const { range, direction, delay } = options;
    if (!range) {
      options.range = [0, val(sheet.sequence.pointer.length)];
    }
    if (!direction) {
      options.direction = 'normal';
    }

    playOptions.current = options;
    if (delay)
      setTimeout(() => {
        sheet.sequence.play(options);
        isPlaying.current = true;
      }, delay * 1000);
    else {
      sheet.sequence.play(options);
      isPlaying.current = true;
    }
  };

  const pause = () => {
    sheet?.sequence.pause();
  };

  useEffect(() => {
    if (!sheet) return;
    let cleanUpFunctions: (() => void)[] = [];
    if (onComplete) {
      const unsubscribe = onChange(sheet.sequence.pointer.playing, (playing) => {
        if (!isPlaying.current) return;
        const start = playOptions.current.range![0];
        const end = playOptions.current.range![1];
        if (!playing && (sheet.sequence.position >= end || sheet.sequence.position <= start)) {
          onComplete(playOptions.current.direction);
          isPlaying.current = false;
        }
      });
      cleanUpFunctions.push(unsubscribe);
    }
    if (triggers) {
      const unsubscribe = onChange(sheet.sequence.pointer.position, (position) => {
        triggers.forEach((trigger, idx) => {
          if (!isPlaying.current || hasCalledTrigger.current[idx]) return;
          if (playOptions.current.direction === 'normal') {
            if (trigger.time >= playOptions.current.range![0] && trigger.time <= position) {
              trigger.function();
              hasCalledTrigger.current[idx] = true;
            }
          } else if (trigger.timeReverse <= playOptions.current.range![1] && trigger.timeReverse >= position) {
            trigger.function();
            hasCalledTrigger.current[idx] = true;
          }
        });
      });
      cleanUpFunctions.push(unsubscribe);
    }

    return () => {
      cleanUpFunctions.forEach((unsubscribe) => unsubscribe());
    };
  }, [sheet, options, onComplete, triggers]);

  /**
   * @param time The current time in seconds
   */
  const setCurrentTime = (time: number) => {
    if (sheet) sheet.sequence.position = time;
  };

  return { play, pause, setCurrentTime };
}
