import ARROW_LEFT_ICON_URL from 'assets/icons/arrow-left.svg';
import ARROW_LEFT_2_ICON_URL from 'assets/icons/arrow-left-2.svg';
import useAppStore from 'hooks/useAppStore';
import { useCallback, useEffect } from 'react';
import Sound from 'utils/sound';
import { shallow } from 'zustand/shallow';

export default function ScreenBase() {
  const [currentScreen, currentStep, showOverlay, totalSteps, nextStep, previousStep] = useAppStore(
    (s) => [s.currentScreen, s.currentStep, s.showOverlay, s.totalSteps, s.nextStep, s.previousStep],
    shallow
  );
  const percentCompleted = ((currentStep - 1) / (totalSteps - 1)) * 100;

  const handleKeyPress = useCallback(
    (e: any) => {
      if (!showOverlay) return;
      if (e?.keyCode === 37) {
        Sound.playSfxClick();
        currentStep > 1 && previousStep();
      } else if (e?.keyCode === 39) {
        Sound.playSfxClick();
        currentStep < totalSteps && nextStep();
      }
    },
    [currentStep, nextStep, previousStep, showOverlay, totalSteps]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  return (
    <div className="absolute top-0 left-0 w-full h-full z-20 overflow-hidden items-center select-none pointer-events-none">
      {showOverlay && (
        <>
          {/* title */}
          {currentScreen?.title && !currentScreen.isTitleHidden && (
            <div className="mt-8 text-center">
              <span className={'whitespace-nowrap ph-20 text-h2 text-white gradiend-underline'}>
                {currentScreen.title}
              </span>
            </div>
          )}

          <div className="w-full absolute bottom-2 left-0">
            {/* back */}
            {previousStep && currentStep > 2 && !currentScreen?.isWithSkipButton && (
              <div
                className="absolute bottom-6 left-10 cursor-pointer justify-center bg-gradient-radial group pointer-events-auto"
                onClick={previousStep}
                onMouseDown={() => Sound.playSfxClick()}
                onMouseEnter={() => Sound.playSfxHover()}
              >
                <img
                  alt="arrow-left"
                  className="inline mb-3 ml-1 group-hover:ml-0 transition-all duration-400"
                  src={ARROW_LEFT_ICON_URL}
                />
                <img
                  alt="arrow-left-2"
                  className="inline mb-3 ml-[-10px] mr-4 group-hover:ml-[-3px] transition-all duration-400"
                  src={ARROW_LEFT_2_ICON_URL}
                />
                <span className="text-h3 text-white group-hover:text-orange transition-colors duration-400">BACK</span>
              </div>
            )}

            {/* next */}
            {nextStep && currentStep > 2 && currentStep < totalSteps && !currentScreen?.isWithSkipButton && (
              <div
                className="absolute bottom-6 right-10 cursor-pointer justify-center bg-gradient-radial group pointer-events-auto"
                onClick={nextStep}
                onMouseDown={() => Sound.playSfxClick()}
                onMouseEnter={() => Sound.playSfxHover()}
              >
                <span className="text-h3 text-white group-hover:text-orange transition-colors">NEXT</span>
                <img
                  alt="arrow-right-2"
                  className="inline mb-3 mr-[-10px] ml-4 rotate-180 group-hover:mr-[-3px] transition-all duration-400"
                  src={ARROW_LEFT_2_ICON_URL}
                />
                <img
                  alt="arrow-right"
                  className="inline mb-3 mr-1 rotate-180 group-hover:mr-0 transition-all duration-400"
                  src={ARROW_LEFT_ICON_URL}
                />
              </div>
            )}
          </div>

          {/* skip */}
          {nextStep && currentScreen?.isWithSkipButton && (
            <div
              className="absolute top-[120px] right-10 cursor-pointer justify-center bg-gradient-radial group pointer-events-auto"
              onClick={nextStep}
              onMouseDown={() => Sound.playSfxClick()}
              onMouseEnter={() => Sound.playSfxHover()}
            >
              <span className="text-h3 text-white group-hover:teyxt-orange transition-colors">SKIP</span>
              <img
                alt="arrow-right-2"
                className="inline mb-3 mr-[-10px] ml-4 rotate-180 group-hover:mr-[-3px] transition-all duration-400"
                src={ARROW_LEFT_2_ICON_URL}
              />
              <img
                alt="arrow-right"
                className="inline mb-3 mr-1 rotate-180 group-hover:mr-0 transition-all duration-400"
                src={ARROW_LEFT_ICON_URL}
              />
            </div>
          )}
        </>
      )}

      {/* progress */}
      <div className="w-full absolute bottom-0 h-2 flex">
        <div className="h-2 bg-orange transition-all duration-1000" style={{ width: percentCompleted + '%' }} />
        <div
          className="h-2 bg-orange opacity-20 w-full transition-all duration-1000"
          style={{ width: 100 - percentCompleted + '%' }}
        />
      </div>
    </div>
  );
}
