import Sound from 'utils/sound';

export default function CloseButton({ onClose }: { onClose: any }) {
  return (
    <div
      className="circle-button right-[64px] top-[32px] pointer-events-auto group"
      onClick={onClose}
      onMouseDown={() => Sound.playSfxClick()}
      onMouseEnter={() => Sound.playSfxHover()}
    >
      <div className="circle-1" />
      <div className="circle-2" />
      <div className="absolute top-[32px] left-[18px] border-[white] group-hover:border-[orange] bg-[white] group-hover:bg-[orange] border-[1px] w-[33px] h-[3px] rounded-full rotate-45 transition-all" />
      <div className="absolute top-[32px] left-[18px] border-[white] group-hover:border-[orange] bg-[white] group-hover:bg-[orange] border-[1px] w-[33px] h-[3px] rounded-full -rotate-45 transition-all" />
    </div>
  );
}
