import { useEffect, useMemo, useRef, useState } from 'react';
import { useAnimations, useGLTF, useTexture } from '@react-three/drei';
import {
  AnimationClip,
  BackSide,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  PerspectiveCamera,
  Texture,
  TextureLoader,
  Vector2,
  sRGBEncoding
} from 'three';
import Robot from 'components/Robot/Robot';
import { useFrame, useThree } from '@react-three/fiber';
import useAppStore from 'hooks/useAppStore';
import { gsap, Power2 } from 'gsap';
import LOGOS_TEXTURE from 'assets/textures/LOGOS.png';
import ARTWORKS_TEXTURE from 'assets/textures/ARTWORKS.png';
import useLookAroundCamera from 'hooks/useLookAroundCamera';
import useTheaterAnimation from 'hooks/useTheaterAnimation';
import { TiltingPicture } from 'components/TiltingPicture/TiltingPicture';
import LANDSCAPE_LOW_QUALITY from 'assets/models/Mars Scene/Mars Scene 1K.glb';
import LANDSCAPE_MID_QUALITY from 'assets/models/Mars Scene/Mars Scene 2K.glb';
import LANDSCAPE_HIGH_QUALITY from 'assets/models/Mars Scene/Mars Scene 4K.glb';
import { shallow } from 'zustand/shallow';
import SKYBOX_1_TEXTURE from 'assets/textures/skybox-1.jpg';

const STEP_TIMELINES: { [step: number]: [number, number] } = {
  13: [0.0, 2],
  15: [2.0, 4.0],
  16: [4.0, 10.0],
  19: [10.0, 12.0]
};

export default function MarsScene({ visible }: { visible: boolean }) {
  const camera = useThree((s) => s.camera);
  const [
    currentStep,
    direction,
    hasEnteredAfterLoad,
    nextStep,
    previousStep,
    setShowOverlay,
    background,
    quality,
    setLoadingBackground
  ] = useAppStore(
    (s) => [
      s.currentStep,
      s.direction,
      s.hasEnteredAfterLoad,
      s.nextStep,
      s.previousStep,
      s.setShowOverlay,
      s.background,
      s.quality,
      s.setLoadingBackground
    ],
    shallow
  );

  const [skyboxMesh, setSkyboxMesh] = useState<Mesh | null>(null);
  const landscape = useGLTF([LANDSCAPE_LOW_QUALITY, LANDSCAPE_MID_QUALITY, LANDSCAPE_HIGH_QUALITY]);

  const textureLoader = useMemo(() => new TextureLoader(), []);

  const usingDefaultTexture = useRef(true);

  useEffect(() => {
    if (!skyboxMesh) return;
    if (background !== 'default') setLoadingBackground(true);
    textureLoader.load(background === 'default' ? SKYBOX_1_TEXTURE : background, (t) => {
      t.encoding = sRGBEncoding;
      if (background === 'default') {
        skyboxMesh.position.set(0, 300, 40);
        t.repeat = new Vector2(1.9, 1.9);
        t.center = new Vector2(0.08, 0.4);
      } else {
        t.repeat = new Vector2(1.0, 1.0);
        t.center = new Vector2(0, 0);
      }

      (skyboxMesh.material as MeshBasicMaterial).map?.dispose();
      (skyboxMesh.material as MeshBasicMaterial).map = t;
      (skyboxMesh.material as MeshBasicMaterial).needsUpdate = true;
      if (background !== 'default') {
        setLoadingBackground(false);
        usingDefaultTexture.current = false;
      }
    });
  }, [background, skyboxMesh]);

  const marsSequence = useTheaterAnimation('marsSequence', {
    onComplete: () => {
      switch (currentStep) {
        case 13:
          if (direction === 'normal') {
            setShowOverlay(true);
            nextStep();
          } else if (direction === 'reverse') {
            previousStep();
          }
          break;
        case 19:
          if (direction === 'reverse') {
            setShowOverlay(true);
            previousStep();
          } else if (direction === 'normal') {
            nextStep();
          }
          break;
        default:
          setShowOverlay(true);
      }
    }
  });

  useEffect(() => {
    if (
      !visible ||
      !hasEnteredAfterLoad ||
      (currentStep === 14 && direction === 'normal') ||
      (currentStep === 18 && direction === 'reverse')
    )
      return;
    setShowOverlay(false);
    if (currentStep === 13 || currentStep === 19) {
      if ((direction === 'normal' && currentStep === 19) || (direction === 'reverse' && currentStep === 13)) {
        marsSequence.play({ direction, range: STEP_TIMELINES[currentStep] });
        const transition = gsap.to('#transition', {
          opacity: 1.0,
          duration: 1.0,
          delay: 1.0,
          ease: Power2.easeInOut
        });
        return () => {
          transition.kill();
        };
      } else if ((direction === 'reverse' && currentStep === 19) || (direction === 'normal' && currentStep === 13)) {
        marsSequence.play({ direction, range: STEP_TIMELINES[currentStep] });
        const transition = gsap.to('#transition', {
          opacity: 0.0,
          duration: 1.0,
          ease: Power2.easeInOut
        });
        return () => {
          transition.kill();
        };
      }
    }

    if (direction === 'normal') marsSequence.play({ direction, range: STEP_TIMELINES[currentStep] });
    else marsSequence.play({ direction, range: STEP_TIMELINES[currentStep + 1] });
  }, [currentStep, hasEnteredAfterLoad]); // eslint-disable-line
  const zoomOptions = useMemo(() => {
    switch (currentStep) {
      case 14:
        return {
          speed: 0.05,
          minFOV: 30,
          maxFOV: 50
        };
      case 18:
      case 19:
        return { speed: 0.0, minFOV: 80, maxFOV: 80 };
      default:
        return undefined;
    }
  }, [currentStep]);

  useLookAroundCamera(camera as PerspectiveCamera, 0.1, 0.05, {
    disabled: !visible,
    zoomOptions
  });

  useEffect(() => {
    landscape.forEach((landscape, idx) => {
      (landscape.materials[`LM2_Mecha.00${3 - idx}`] as MeshStandardMaterial).metalness = 0.5;
    });
  }, [landscape]);

  const [lowQualityAnimation, mediumQualityAnimation, highQualityAnimation] = [
    useAnimations(landscape[0].animations, landscape[0].scene),
    useAnimations(landscape[1].animations, landscape[1].scene),
    useAnimations(landscape[2].animations, landscape[2].scene)
  ];
  useEffect(() => {
    function toggleAnimation(animation: any, idx: number) {
      animation.names.forEach((name: string) => {
        if (quality === idx) animation.actions[name]?.play();
        else animation.actions[name]?.stop();
      });
    }

    toggleAnimation(lowQualityAnimation, 0);
    toggleAnimation(mediumQualityAnimation, 1);
    toggleAnimation(highQualityAnimation, 2);
  }, [lowQualityAnimation, mediumQualityAnimation, highQualityAnimation, quality]);

  useEffect(() => {
    landscape[quality].scene.children[0].traverse(
      (child) => (child.frustumCulled = !(12 < currentStep && currentStep < 16))
    );
  }, [currentStep, quality, landscape]);

  useFrame((state, delta) => {
    if (!camera.parent?.position || !skyboxMesh || usingDefaultTexture.current) return;
    skyboxMesh.position.set(camera.parent.position.x, camera.parent.position.y, camera.parent.position.z);
    skyboxMesh.rotation.y += delta * 0.02;
  });

  return (
    <>
      <group visible={visible} rotation={[0, -Math.PI / 2, 0]}>
        <mesh
          ref={setSkyboxMesh}
          rotation={[0, Math.PI, 0]}
          position={[0, 0, 0]}
          scale={[-1, 1, 1]}
          userData={{
            animated: true,
            animations: [
              {
                sheetName: 'background',
                objectName: 'backgroundPos'
              }
            ]
          }}
        >
          <sphereGeometry args={[1000, 32, 32]}></sphereGeometry>
          <meshBasicMaterial fog={false} color={'white'} side={BackSide} />
        </mesh>
        <ambientLight color={'#ffffff'} intensity={0.7} />
        <Robot robot={landscape[quality].scene.children[0]} />
        <TiltingPicture pictureUrl={LOGOS_TEXTURE} id="logos" />
        <directionalLight position={[-50, 70, -20]} color={'#fad9a4'} intensity={1.0} />
      </group>
      <group
        visible={visible}
        userData={{
          animated: true,
          animations: [
            {
              sheetName: 'marsSequence',
              objectName: 'landscape'
            }
          ]
        }}
      >
        <primitive object={landscape[quality].scene} />
      </group>
    </>
  );
}
