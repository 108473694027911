import { memo, useEffect, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { GroupProps } from '@react-three/fiber';
import { AdditiveBlending, BackSide, Color, FrontSide, Group, Mesh, Uniform } from 'three';
import HOLOGRAM_PLANET_MODEL from 'assets/models/mars.glb';
import GLOW_VERTEX_SHADER from 'shaders/glow.vertex.glsl';
import GLOW_FRAGMENT_SHADER from 'shaders/glow.fragment.glsl';
import useAppStore from 'hooks/useAppStore';

function Mars(props: GroupProps) {
  const { nodes, materials } = useGLTF(HOLOGRAM_PLANET_MODEL);
  const [meshRef, setMeshRef] = useState<Group | null>(null);
  const currentStep = useAppStore((s) => s.currentStep);
  const direction = useAppStore((s) => s.direction);

  useEffect(() => {
    if (currentStep === 11 && direction === 'reverse') {
      meshRef?.scale.set(0.1, 0.1, 0.1);
      meshRef?.updateMatrix();
      meshRef?.updateMatrixWorld();
    }
  }, [currentStep]); // eslint-disable-line

  return (
    <group
      userData={{
        animated: true,
        animations: [
          meshRef?.parent?.userData.isSpaceStationScene
            ? { sheetName: 'spaceSequence', objectName: 'mars' }
            : { sheetName: 'earthSequence', objectName: 'mars' }
        ]
      }}
      scale={[0.1, 0.1, 0.1]}
      {...props}
      ref={setMeshRef}
    >
      <group rotation={[Math.PI / 2 - 0.1, Math.PI / 2, -0.2]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[0.0, 0.0, 0.0]} scale={35.44}>
            <mesh scale={[1.2, 1.2, 1.2]} geometry={(nodes.Object_4 as Mesh).geometry}>
              <shaderMaterial
                transparent
                blending={AdditiveBlending}
                vertexShader={GLOW_VERTEX_SHADER}
                fragmentShader={GLOW_FRAGMENT_SHADER}
                side={BackSide}
                uniforms={{
                  glowColor: new Uniform(new Color('#6161ff')),
                  power: new Uniform(0.3),
                  baseOpacity: new Uniform(0.0),
                  opacity: new Uniform(1.0),
                  spread: new Uniform(1.0)
                }}
              />
            </mesh>
            <mesh geometry={(nodes.Object_4 as Mesh).geometry} material={materials.Planets} />
            <mesh scale={[1.02, 1.02, 1.02]} geometry={(nodes.Object_4 as Mesh).geometry}>
              <shaderMaterial
                transparent
                blending={AdditiveBlending}
                vertexShader={GLOW_VERTEX_SHADER}
                fragmentShader={GLOW_FRAGMENT_SHADER}
                side={FrontSide}
                uniforms={{
                  glowColor: new Uniform(new Color('#6161ff')),
                  power: new Uniform(0.05),
                  baseOpacity: new Uniform(1.15),
                  opacity: new Uniform(1.0),
                  spread: new Uniform(-0.6)
                }}
              />
            </mesh>
          </group>
        </group>
      </group>
    </group>
  );
}

export default memo(Mars);
