import SOLUTION_VIDEO from 'assets/videos/pw-solution.mp4';
import SCREEN_6_HEADING from 'assets/images/screen-6-heading.svg';
import useAppStore from 'hooks/useAppStore';
import { useEffect, useRef } from 'react';

export default function SolutionVideo() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const currentScreen = useAppStore((s) => s.currentScreen);
  const showOverlay = useAppStore((s) => s.showOverlay);

  const isSolutionScreen = currentScreen?.isSolutionScreen;
  useEffect(() => {
    setTimeout(() => {
      if (!videoRef.current) return;
      if (isSolutionScreen && showOverlay) videoRef.current.play();
      else videoRef.current.pause();
    }, 500);
  }, [isSolutionScreen, showOverlay]);

  return (
    <div className="w-[53%] h-auto flex flex-col items-center">
      <img alt="The 1st no-code 3d website bulder" className="mb-[40px] w-[77%]" src={SCREEN_6_HEADING} />
      <video className="h-full w-full" controls loop ref={videoRef}>
        <source src={SOLUTION_VIDEO} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}
